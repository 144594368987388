import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
function PrivacyPolicy() {
    const navigate = useNavigate();
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
       
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <>
            <section className="privacy-policy">
                <div className="privacy-title">
                    <h1>
                        Privacy<span className="red"> Policy</span>
                    </h1>
                    <hr className="bottom-border" />
                </div>
                <div className="container">
                    <div className="privacy">
                        <ul>
                            <li>
                                Said to Contain Basis
                                <br />
                                <small>
                                    All the consignment shall be booked on "said to contain basis"
                                    i.e. Nihao Logistics shall be under no obligation to verify the
                                    description and contents of the shipment declared by the Consignor
                                    on the docket/Invoice and as such the consignor shall undertake
                                    and ensure to make the correct and factual declaration on the
                                    docket/Airway bill. However, Nihao Logistics reserve the right to
                                    inspect and/or refuse booking of shipment not conforming to these
                                    terms and condition, only after duly informing consignor regarding
                                    the reasons for such inspection and/or refusal and after receiving
                                    the confirmation from consignor. Consignor shall not book,
                                    handover any shipment consisting of prohibited, restricted or
                                    dangerous products and Nihao Logistics shall not be liable for any
                                    such shipment. Consignor indemnified and would keep indemnified
                                    Nihao Logistics, its directors and employees against all claims,
                                    losses, charges and expenses incurred by Nihao Logistics due to
                                    any banned, restricted or dangerous items entering in to the
                                    network of the Nihao Logistics due to any commission or omission
                                    of Consignor.
                                </small>
                            </li>
                            <li>
                                In case of loss, theft, damage
                                <br />
                                <small>
                                    In case of loss, theft, damage, and mishandling of booked
                                    consignment maximum liability of the Company/ Franchisee shall not
                                    be exceeding to the sum equivalent to ten times of freight charges
                                    for un-insured documents and five times of freight charges for
                                    un-Insured parcels, in any case.
                                </small>
                            </li>
                            <li>
                                Prohibited Items
                                <br />
                                <p className="text-justify">
                                    <small>
                                        Dangerous Goods are not accepted for carriage on Nihao logistics
                                        services. The list given below is not exhaustive and is issued
                                        mainly for your guidance. An exhaustive list may be seen in the
                                        current "IATA DANGEROUS GOODS REGULATIONS" handbook, or you may
                                        contact with us for further assistance.
                                    </small>
                                </p>
                                <section>
                                    <div className="container">
                                        <div className="document-list">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <ul className="list-unstyled link" id="rmovStyle">
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Liquid,
                                                            semi-liquid and gases.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Currency.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Indian
                                                            Postal Articles and all items that infringe the Indian
                                                            Postal Act of 1898.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Precious and semi-precious stones, gems, jewellery and
                                                            equivalent items.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Perishables.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Radioactive or magnetic material.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Negotiable Instruments (Bearer Form).
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Pornographic material.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Sodexo
                                                            pass.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Firearms, explosives and military equipment.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Flammable Items.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Toxic
                                                            and infectious Items.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Corrosives.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Powders
                                                            and chemicals.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" /> Drugs.
                                                        </li>
                                                        <li>
                                                            <i className="fa fa-ban" aria-hidden="true" />{" "}
                                                            Machinery parts containing oil, grease, fuel or
                                                            batteries.
                                                        </li>
                                                        <li>
                                                            <a
                                                                href="https://www.iata.org/publications/dgr/Pages/index.aspx"
                                                                target="_blank"
                                                            >
                                                                <i className="fa fa-ban" aria-hidden="true" /> All
                                                                restricted items as per guidelines of IATA.
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </li>
                            <li>
                                No Service Area
                                <br />
                                <small>
                                    Where the dox or Non-dox booked unknowingly for the area which
                                    comes under NSA (No Service Area) i.e. beyond the network of Nihao
                                    Logistics in such event the said shipment shall be returned to the
                                    party/consignor. In case of NSA service the Maximum Liability of
                                    the Company's'/Franchisees' shall be limited up to return of
                                    freight amount only.
                                </small>
                            </li>
                            <li>
                                Applicability
                                <br />
                                <small>
                                    The provision set out and referred to in this consignment note
                                    shall apply to transportation through any mode and shall not be
                                    restricted to one mode of transport only.
                                </small>
                            </li>
                            <li>
                                Mode of Transport
                                <br />
                                <small>
                                    Nihao Logistics is entitled to use any mode and route for
                                    transportation.
                                </small>
                            </li>
                            <li>
                                Contract
                                <br />
                                <small>
                                    The docket is a contract between Nihao Logistics and customer.
                                </small>
                            </li>
                            <li>
                                Non-Negotiable
                                <br />
                                <small>
                                    The Nihao Logistics Waybill/Consignment Note is non-negotiable and
                                    the consignor acknowledges that it has been prepared by the
                                    Consignor or by Nihao Logistics on behalf of the Consignor.
                                </small>
                            </li>
                            <li>
                                Acceptance of Terms and Conditions
                                <br />
                                <small>
                                    By giving us your shipment you accept our terms and conditions set
                                    out in the consignment note and/or the contract of carriage and/or
                                    the contract for the performance of other services on behalf of
                                    yourself and/or anyone else who has an interest in the shipment or
                                    the performance of other services irrespective of whether you have
                                    signed the front of our consignment note or not. Our terms and
                                    conditions also cover and can be invoked by anyone we use or
                                    sub-contract to collect, transport, deliver your shipment or
                                    perform other services as well as our employees, directors and
                                    agents. Only one of our authorized officers may agree to a
                                    variation of these terms and conditions in writing. When you give
                                    us the shipment with oral or written instructions that conflict
                                    with our terms and conditions we shall not be bound by such
                                    instructions.
                                </small>
                            </li>
                            <li>
                                Insurance
                                <br />
                                <small>
                                    Nihao Logistics does not offer any insurance cover for the
                                    shipments and insist to customer to hand over insured goods for
                                    transportation under the consignor insurance. In the event of any
                                    loss, theft and damage of shipment maximum liability of Nihao
                                    Logistics is up to provision of COF only.
                                </small>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <div
                className="modal fade modal-start-project"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="btnCloseModal"
                                className="btn-close close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="flex-row">
                                <div className="col modal-left-col modal-info">
                                    <div className="highlighted-serivces">
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/delivery.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Safe &amp; Secure Delivery</h5>
                                                <p>
                                                    A higher level of competence, using state-of-the-art
                                                    software and experienced professionals to improve overall
                                                    safety and security for parcel.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/save-money.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Cost Savings</h5>
                                                <p>
                                                    Effective instruments, which aim to control cost by
                                                    harnessing the power of operational optimization, offer a
                                                    more cost-effective, flexible and dynamic solution of
                                                    delivering on time than conventional measures.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/optimization.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Transparency</h5>
                                                <p>Service Price Calculater-What You See is What is Pay.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/deadline.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Assurance</h5>
                                                <p>
                                                    Continued Promise of Extended Warranty &amp; Part
                                                    Availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col modal-right-col modal-form">
                                    <div className="content-inner">
                                        <div className="form-headingtext">
                                            <span className="title-label">Connect with us</span>
                                            <h2>
                                                Get <span>No-Cost Quote</span> and Expert Consultation
                                            </h2>
                                        </div>
                                        <div className="contact-form project-form">
                                            <div
                                                className="wpcf7 js"
                                                id="wpcf7-f36355-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                    <ul />
                                                </div>
                                                <form
                                                    action="#"
                                                    method="post"
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                >
                                                    <div className="form-group">
                                                        <p>
                                                            <label>Name*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Email*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Company/Organization </label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="company-organization"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="company-organization"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> How can we help you?*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    name="your-message"
                                                                    defaultValue={""}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                id="chkIagree"
                                                            />
                                                            I confirm that I have read and understood the Terms of
                                                            Service and Privacy Policy *
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <a
                                                                href="#"
                                                                id="SaveBTN"
                                                                className="wow fadeInLeft"
                                                                style={{
                                                                    visibility: "visible",
                                                                    animationName: "fadeInLeft"
                                                                }}
                                                            >
                                                                Submit
                                                                <span>
                                                                    <img
                                                                        src="images/readmore.png"
                                                                        alt="arrow-right"
                                                                    />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back_top" onclick="topFunction()" id="myBtn">
                <i className="fa fa-long-arrow-alt-right" />
            </div>
        </>

    )
}
export default PrivacyPolicy