
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
function Kyc() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    const inputrefs = useRef([]);
    const [formData, setFormData] = useState({
        AwbNo: '',
        Name: '',
        PhoneNo: '',
        Email: '',
        Address1: '',
        Address2: '',
        Country: '',
        State: '',
        City: '',
        ZipCode: '',
        AdCode: '',
    });
    const HomeSection = document.getElementById('HomeSection');

    const ScrollFunction = async () => {
        // Scroll to the districtDataTable
        if (HomeSection) {
            HomeSection.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

        }
    }

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        const value = isChecked ? 'GSTCERTIFICATE' : '';

        setKycDetails({ CertificateType: value });
    };



    const [KycDetail, setKycDetails] = useState([
        {
            "CertificateType": "GSTCERTIFICATE",
            'label':'GST CERTIFICATE',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status':false
        },
        {
            "CertificateType": "IEC",
            'label': 'IEC',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "PANCard",
            'label': 'PAN Card',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AadharCard",
            'label': 'Aadhar Card',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AddressProof",
            'label': 'Address Proof',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AuthorizationLetter",
            'label': 'Authorization Letter',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AdCode",
            'label': 'Ad Code',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        }
    ])
    const initialKycDetails = [
        {
            "CertificateType": "GSTCERTIFICATE",
            'label': 'GST CERTIFICATE',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "IEC",
            'label': 'IEC',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "PANCard",
            'label': 'PAN Card',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AadharCard",
            'label': 'Aadhar Card',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AddressProf",
            'label': 'Address Prof',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AuthorizationLetter",
            'label': 'Authorization Letter',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        },
        {
            "CertificateType": "AdCode",
            'label': 'Ad Code',
            "FileName": "",
            "FileExt": "",
            "File": "",
            'status': false
        }
    ];
    const initialFormData = {
        AwbNo: '',
        Name: '',
        PhoneNo: '',
        Email: '',
        Address1: '',
        Address2: '',
        Country: '',
        State: '',
        City: '',
        ZipCode: '',
        AdCode: '',

    };
 
    //};
    const HandleChange = (prop, value) => {
        setFormData(formData => ({ ...formData, [prop]: value }))
    }
    const token = process.env.REACT_APP_SECRET_KEY
    const LoginToken = process.env.REACT_APP_LOGIN_KEY

    //token Data
    const TokenData = {
        AuthToken: token,
        LoginToken: LoginToken,
        UserCode: "Admin"
    }
    const resetFormData = () => {
        setFormData(initialFormData);
    }

    const resetKycDetails = () => {
        setKycDetails(initialKycDetails);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const selectedKyc = KycDetail.filter(item => item.status);
       
        let temp = {
            ...formData,
            KycDetail: selectedKyc
        }

        console.log('temp', temp)
        const data = { ...temp, ...TokenData  }
        await fetch(`api/Web/SubmitKYC`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'SUCCESS') {
                //KycDetail.forEach((item, index) => {
                //    KycDetail[index].File = '';
                //    KycDetail[index].FileExt = '';
                //    KycDetail[index].FileName = '';
                //});
                let fileInputs = document.querySelectorAll('input[type="file"]');

                fileInputs.forEach(input => {

                    input.value = '';
                });
                toast.success(res.message)
                resetFormData();
                resetKycDetails(); 
            } else if (res.status === 'ERROR') {
               
                toast.error(res.message, {
                    position: 'top-center',
                });
            }
            inputrefs.current[res.focus].focus()
        })
    }
  
    useEffect(() => {
        inputrefs.current['txtAwbNo'].focus()

        document.title = 'Know Your Customer (KYC)| Nihao Logistics'
        window.scrollTo(0, 0);
    }, [navigate])
   
    return (

            <>
        <Helmet>
                <title>Know Your Customer (KYC)| Nihao Logistics </title>
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                <meta name="description" content="Complete your Know Your Customer (KYC) requirements with ease on our logistics website. Ensure smooth and compliant transactions with Nihao Logistics." />
                <meta property="og:title" content="Know Your Customer (KYC)| Nihao Logistics" />
                <meta property="og:description" content="Complete your Know Your Customer (KYC) requirements with ease on our logistics website. Ensure smooth and compliant transactions with Nihao Logistics." />
                <meta property="og:url" content="https://www.nihaologistics.com/kyc" />
            </Helmet>
            <section className="home-section" id="HomeSection">
                    <div className="container">
                        <div className="kyc-banner">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-10 mx-auto">
                                    <div className="kyc-content">
                                        <div className="col-lg-10">
                                            <h1>
                                                Submit<span className="red"> KYC</span>
                                            </h1>
                                            <p>
                                                All shipments sent from or received into India need KYC
                                                documents of the consignee (Government recognized Identity and
                                                Address proof) to be presented to Customs during shipment
                                                clearance (reference: Customs Circular No. 9/2010).
                                            </p>
                                            <div className="nihaobtn mt-3 mb-4">
                                                <a
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                    id="SaveBTN"
                                                    className="wow fadeInLeft"
                                                    style={{
                                                        visibility: "visible",
                                                        animationName: "fadeInLeft"
                                                    }}
                                                >
                                                    Get a Free Quote
                                                    <span>
                                                        <img src="images/readmore.png" alt="arrow-right" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="kyc-right-banner">
                                        <img
                                            src="images/submit-kyc.png"
                                            className="img-fluid"
                                            alt="submit kyc"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="kyc-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="kyc-box">
                                    <p>All marked fields(*) are mandatory.</p>
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <input type="hidden" id="HdnKycId" defaultValue={0} />
                                            <div className="form-group">
                                                <label className="form-label">
                                                    AWB No. <span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtAwbNo'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtName'].focus()
                                                    };
                                                }}
                                                value={formData.AwbNo}
                                                onChange={(e) => HandleChange('AwbNo', e.target.value)}
                                                    id="txtAWBNo"
                                                    style={{ textTransform: "uppercase" }}
                                                    className="form-control"
                                                    placeholder="AWB No."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Name<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtName'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtPhoneNo'].focus()
                                                    };
                                                }}
                                                value={formData.Name}
                                                onChange={(e) => HandleChange('Name', e.target.value)}
                                                   
                                                    className="form-control"
                                                    placeholder="Name"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Phone No. <span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtPhoneNo'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtEmail'].focus()
                                                    };
                                                }}
                                                value={formData.PhoneNo}
                                                onChange={(e) => HandleChange('PhoneNo', e.target.value)}
                                                  
                                                    className="form-control"
                                                    placeholder="Phone No."
                                                    maxLength={15}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Email<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="Email"
                                                ref={ref => (inputrefs.current['txtEmail'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtAddress1'].focus()
                                                    };
                                                }}
                                                value={formData.Email}
                                                onChange={(e) => HandleChange('Email', e.target.value)}
                                                   
                                                    className="form-control"
                                                    placeholder="Email"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Address 1 <span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtAddress1'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtAddress2'].focus()
                                                    };
                                                }}
                                                value={formData.Address1}
                                                onChange={(e) => HandleChange('Address1', e.target.value)}
                                                 
                                                    className="form-control"
                                                    placeholder="Address 1"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Address 2 <span className="text-danger" />{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtAddress2'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtCountry'].focus()
                                                    };
                                                }}
                                                value={formData.Address2}
                                                onChange={(e) => HandleChange('Address2', e.target.value)}
                                                  
                                                    className="form-control"
                                                    placeholder="Address 2"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Country<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtCountry'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtState'].focus()
                                                    };
                                                }}
                                                value={formData.Country}
                                                onChange={(e) => HandleChange('Country', e.target.value)}
                                                  
                                                    className="form-control"
                                                    placeholder="Country"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    State
                                                    <span className="text-danger" />{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtState'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtCity'].focus()
                                                    };
                                                }}
                                                value={formData.State}
                                                onChange={(e) => HandleChange('State', e.target.value)}
                                                   
                                                    className="form-control"
                                                    placeholder="State"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    City
                                                    <span className="text-danger" />{" "}
                                                </label>
                                                <input
                                                type="text"
                                                ref={ref => (inputrefs.current['txtCity'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtZipCode'].focus()
                                                    };
                                                }}
                                                value={formData.City}
                                                onChange={(e) => HandleChange('City', e.target.value)}
                                                 
                                                    className="form-control"
                                                    placeholder="City"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    Zip-Code<span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                id="txtZipCode"
                                                ref={ref => (inputrefs.current['txtZipCode'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        inputrefs.current['txtADCode'].focus()
                                                    };
                                                }}
                                                value={formData.ZipCode}
                                                onChange={(e) => HandleChange('ZipCode', e.target.value)}
                                                    className="form-control"
                                                    placeholder="Zip-Code"
                                                    maxLength={6}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    AD CODE / Bank Authorised Dealer Code
                                                    <span className="text-danger">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                id="txtADCode"
                                                ref={ref => (inputrefs.current['txtADCode'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                       
                                                    };
                                                }}
                                                value={formData.AdCode}
                                                onChange={(e) => HandleChange('AdCode', e.target.value)}
                                                    className="form-control"
                                                    placeholder="AD CODE / Bank Authorised Dealer Code"
                                                    maxLength={6}
                                                />
                                            </div>
                                        </div>
                                </div>
                                <div className="section-heading">
                                    <span className="sub-title active-shape" style={{ fontWeight: 'bold', marginBottom:'4px' }}> Kyc Details :</span>
                                   {/* <h3 className="title">Kyc Details</h3>*/}
                                </div>
                                {KycDetail.map((item, index) => {
                                    return <div className="row mt-4" key={index}>
                                            <div className="col-sm-6">
                                                <div className="form-check-inline" style={{ paddingLeft: 15 }}>
                                                    <input
                                                        type="checkbox"
                                                        name="KYC"
                                                    className="form-check-input"
                                                    checked={item.status}
                                                    defaultValue={item.CertificateType}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            KycDetail[index].CertificateType = e.target.value;
                                                            KycDetail[index].status = e.target.checked;
                                                            KycDetail[index].File = '';
                                                            KycDetail[index].FileExt = '';
                                                            KycDetail[index].FileName = '';
                                                            setKycDetails(KycDetail => [...KycDetail])
                                                        } else {
                                                            KycDetail[index].CertificateType = e.target.value;
                                                            KycDetail[index].status = e.target.checked;
                                                            KycDetail[index].File = '';
                                                            KycDetail[index].FileExt = '';
                                                            KycDetail[index].FileName = '';
                                                            setKycDetails(KycDetail => [...KycDetail])
                                                        }
                                                    }}
                                                        id="chkGST"
                                                    />
                                                    <label className="form-check-label ms-4" >{item.label} </ label >
                                                </div>
                                            </div>
                                            {/*<div className="col-sm-4">*/}
                                            {/*    <div className="form-group">*/}
                                            {/*        <label>*/}
                                            {/*            Choose GST Certificate <span className="text-danger" />{" "}*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <input
                                                    type="file"
                                                    
                                                        name="File"
                                                    id={`${item.label}File`} 
                                                   
                                                    onChange={(e) => {
                                                        const file = e.target.files[0];

                                                        if (file) {
                                                            
                                                            const reader = new FileReader();
                                                            reader.onloadend = () => {
                                                                const base64Data = reader.result.split(',')[1];

                                                                KycDetail[index].File = base64Data;
                                                                KycDetail[index].FileExt = file.name.split('.').pop();
                                                                KycDetail[index].FileName = file.name;
                                                                setKycDetails([...KycDetail]);
                                                            };

                                                            reader.readAsDataURL(file);
                                                        } else {
                                                            // No file selected, set File to an empty string
                                                            KycDetail[index].File = '';
                                                            KycDetail[index].FileExt = '';
                                                            KycDetail[index].FileName = '';
                                                            setKycDetails([...KycDetail]);
                                                        }
                                                    }}
                                                        className="form-control FileName"
                                                    disabled={!item.status}
                                                   
                                                    />
                                                </div>
                                            </div>
                                    </div>   
                                

                               
                                })}
                               
                                    <div className="col-12">
                                        <div className="nihaobtn mt-3 mb-4">
                                            <a
                                            onClick={handleSubmit}
                                                id="SaveGST"
                                                className="wow fadeInLeft"
                                                style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                            >
                                                Submit
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div
                    className="modal fade modal-start-project"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    id="btnCloseModal"
                                    className="btn-close close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="flex-row">
                                    <div className="col modal-left-col modal-info">
                                        <div className="highlighted-serivces">
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/delivery.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Safe &amp; Secure Delivery</h5>
                                                    <p>
                                                        A higher level of competence, using state-of-the-art
                                                        software and experienced professionals to improve overall
                                                        safety and security for parcel.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/save-money.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Cost Savings</h5>
                                                    <p>
                                                        Effective instruments, which aim to control cost by
                                                        harnessing the power of operational optimization, offer a
                                                        more cost-effective, flexible and dynamic solution of
                                                        delivering on time than conventional measures.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/optimization.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Transparency</h5>
                                                    <p>Service Price Calculater-What You See is What is Pay.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/deadline.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Assurance</h5>
                                                    <p>
                                                        Continued Promise of Extended Warranty &amp; Part
                                                        Availability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col modal-right-col modal-form">
                                        <div className="content-inner">
                                            <div className="form-headingtext">
                                                <span className="title-label">Connect with us</span>
                                                <h2>
                                                    Get <span>No-Cost Quote</span> and Expert Consultation
                                                </h2>
                                            </div>
                                            <div className="contact-form project-form">
                                                <div
                                                    className="wpcf7 js"
                                                    id="wpcf7-f36355-o1"
                                                    lang="en-US"
                                                    dir="ltr"
                                                >
                                                    <div className="screen-reader-response">
                                                        <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                        <ul />
                                                    </div>
                                                    <form
                                                        action="#"
                                                        method="post"
                                                        className="wpcf7-form init"
                                                        aria-label="Contact form"
                                                    >
                                                        <div className="form-group">
                                                            <p>
                                                                <label>Name*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-name"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="name"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="your-name"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Email*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-email"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="email"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="email"
                                                                        name="your-email"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Company/Organization </label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="company-organization"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="company-organization"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> How can we help you?*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-message"
                                                                >
                                                                    <textarea
                                                                        cols={40}
                                                                        rows={10}
                                                                        className="form-control"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        name="your-message"
                                                                        defaultValue={""}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-1"
                                                                    id="chkIagree"
                                                                />
                                                                I confirm that I have read and understood the Terms of
                                                                Service and Privacy Policy *
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <Link
                                                                to="#"
                                                                    
                                                                    id="SaveBTN"
                                                                    className="wow fadeInLeft"
                                                                    style={{
                                                                        visibility: "visible",
                                                                        animationName: "fadeInLeft"
                                                                    }}
                                                                >
                                                                    Submit
                                                                    <span>
                                                                        <img
                                                                            src="images/readmore.png"
                                                                            alt="arrow-right"
                                                                        />
                                                                </span>
                                                            </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="wpcf7-response-output"
                                                            aria-hidden="true"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

    )
}
export default Kyc