import { event } from 'jquery';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet';
function Tracking() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const location = useLocation();
    const inputrefs = useRef([]);
    const [tableData, setTableData] = useState([])
    const [formData, setFormData] = useState({
        TrackingNo: ''
    });
    //let url = window.location.search
    //const queryString = new URLSearchParams(url);
    //const TrackingNo = queryString.get('trackingNo');

    const HandleChange = (prop, value) => {
        setFormData(formData => ({ ...formData, [prop]: value }))
    }
    const handleSubmit = async (event, trackingNo) => {
        /*event.preventDefault();*/
        const data = { ...formData, trackingNo }
        await fetch(`api/Tracking/GetTrackingDetails`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            console.log(res.status, "jhgj")

            if (res.status === 'SUCCESS') {

                setTableData(res)
            } else if (res.status === 'ERROR') {
                setTableData([])


            }
            //else if (res.errors.TrackingNo[0]) {
            //    toast.error(res.errors.TrackingNo[0], {
            //        position: 'top-center',

            //    });
            //}

            inputrefs.current[res.focus].focus()
        })
    }


    const params = new URLSearchParams(location.search);
    const trackingNumber = params.get('trackingNumber');

    useEffect(() => {


        document.title = 'Track Your Consignment | Nihao Logistics'

        window.scrollTo(0, 0);
        setFormData({ TrackingNo: trackingNumber });
        handleSubmit('', trackingNumber)
    }, [trackingNumber]);

    return (


        <>
            <Helmet>
                <title>Tracking </title>
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                <meta name="description" content="You can track your shipment anytime anywhere by entering your AWB no." />
                <meta property="og:title" content="Track Your Consignment | Nihao Logistics " />
                <meta property="og:description" content="You can track your shipment anytime anywhere by entering your AWB no." />
                <meta property="og:url" content="https//www.nihaologistics.com/tracking" />
            </Helmet>
            <section className="track-page">
                <div className="container">
                    <h3 className="purple-text">Track Your Shipment</h3>
                    <div className="row track-border">
                        <div className="col-lg-3">
                            <div className="track-left">
                                <p className="orange-text">Enter Your AWB No.</p>
                                <input
                                    name="__RequestVerificationToken"
                                    type="hidden"
                                    defaultValue="EZVae7iXqe--zcgPV8sdS_vZWRAYkuFVg8YksMQOSl5YQmtANwVDcGJFAgLHOKqRx9DzOOa908VE9T45eqFVSH4FIMnHZOYNg9npNRoVHzw1"
                                />
                                <input type="hidden" id="_Token" name="_Token" />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="track-right">
                                <div className="tracking-search-box">
                                    <form action="#">
                                        <div className="search-input">
                                            <div className="form-group">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Please Enter Tracking Number"
                                                    value={formData.TrackingNo}

                                                    onChange={(e) => {
                                                        HandleChange('TrackingNo', e.target.value)

                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="search-btn">
                                            <button className="btn btn-danger" type="submit" onClick={(event) => {
                                                event.preventDefault();
                                                handleSubmit();
                                            }}>
                                                <i className="fa-solid fa-location-dot me-2" />
                                                Show Track Details
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <p>
                                    <span style={{ color: "red" }}>* </span>Enter comma(,) separated Awb
                                    Number to track multiple shipments
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {tableData && tableData.shipmentDetail && tableData.shipmentDetail.length > 0 ? (
                <section className="tracker">
                    {tableData.shipmentDetail.map((shipment, index) => (
                        <div className="container mb-5 track-border">
                            <div className="track-top">

                                <ul>
                                    <span className="bold-text">PacketStatus :</span> {shipment.packetStatus}
                                    <li></li>
                                    <li>
                                        <span className="bold-text">AWB No. :</span> {shipment.orderNo}
                                    </li>
                                    <li>
                                        <span className="bold-text">Booking Date :</span> {shipment.bookingDate}
                                    </li>
                                    <li>
                                        <span className="bold-text">PCS :</span> {shipment.pcs}
                                    </li>
                                    <li>
                                        {shipment.podImage ? (
                                            <div>
                                                <span className="me-2">
                                                    {shipment.hpodImage}
                                                </span>
                                                <button title={shipment.podImage}
                                                    style={{
                                                        backgroundColor: "#43B051",
                                                        display: "inline-block",
                                                        color: "white"
                                                    }}
                                            onClick={() => window.open(`${shipment.podImage}`, "_blank")}>

                                                    <i className="fa fa-eye" />

                                                </button>
                                            </div>
                                        ) : (

                                            <button title={shipment.podImage}
                                                style={{
                                                    backgroundColor: '#FEA802',
                                                    display: "inline-block",
                                                    color: "white",
                                                    cursor: "default"
                                                }}>
                                                <i className="fa fa-spinner me-2" />
                                                {shipment.hpodImage}
                                            </button>
                                        )}
                                    </li>
                                   
                                </ul>
                            </div>

                            <div id="track-data">
                                <div key={index} className="row">
                                    <div className="col-lg-5">
                                        <div className="packet-status">
                                            <h6 className="purple-text">Packet Status</h6>
                                            <table className="table table-bordered table-striped">
                                                <tbody>

                                                    <React.Fragment key={index}>
                                                        {/*<tr >*/}
                                                        {/*    <th> {shipment.hOrderNo} </th>*/}
                                                        {/*    <td>{shipment.orderNo}</td>*/}
                                                        {/*</tr>*/}
                                                        <tr >
                                                            <th> {shipment.hAwbNo} </th>
                                                            <td>{shipment.orderNo}</td>
                                                        </tr>

                                                        <tr >
                                                            <th> {shipment.hBookingDate} </th>
                                                            <td>{shipment.bookingDate}</td>
                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hClient} </th>
                                                            <td>{shipment.client}</td>
                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hWeight} </th>
                                                            <td>{shipment.weight}</td>
                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hConsignorCountry} </th>
                                                            <td>{shipment.consignorCountry}</td>

                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hConsigneeCountry} </th>
                                                            <td>{shipment.consigneeCountry}</td>

                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hConsigneeAddress1} </th>
                                                            <td>{shipment.consigneeAddress1}</td>

                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hTrackingNo} </th>
                                                            <td>{shipment.trackingNo}</td>
                                                        </tr>
                                                        <tr >
                                                            <th> {shipment.hForwardingParty} </th>
                                                            <td>{shipment.forwardingParty}</td>

                                                        </tr>
                                                    </React.Fragment>


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {shipment.trackingDetails && shipment.trackingDetails.length > 0 && (
                                        <div className="col-lg-7">
                                            <div className="packet-history">
                                                <h6 className="purple-text">Packet History</h6>
                                                <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>

                                                            <th>Status Date</th>
                                                            <th>Status Time</th>
                                                            <th>Pod Location</th>
                                                            <th>Status Detail</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {shipment.trackingDetails.map((track, trackIndex) => (
                                                            <tr className="TableTrhover" key={trackIndex}>

                                                                <td>{track.statusDate}</td>
                                                                <td>{track.statusTime}</td>
                                                                <td>{track.podLocation}</td>
                                                                <td>{track.statusDetail}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    ))}
                </section>
            ) : null}

            <div className="back_top" onclick="topFunction()" id="myBtn">
                <i className="fa fa-long-arrow-alt-right" />
            </div>
        </>




    )
}
export default Tracking
