import React, { useEffect, useRef, useState } from 'react';
import {Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import CountUp from 'react-countup';
import { Element } from 'react-scroll';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
/*import { Helmet } from 'react-helmet-async';*/
function Home() {
    const [formData, setFormData] = useState({
        TrackingNo: ''
    });
 
    const [tableData, setTableData] = useState([])
    const navigate = useNavigate();
    const [startCountUp, setStartCountUp] = useState(false);
    const handleScroll = () => {
        const element = document.getElementById('singleCountUp');
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            if (elementPosition < window.innerHeight) {
                setStartCountUp(true);
            }
        }
    };
    const HandleChange = (prop, value) => {
        setFormData(formData => ({ ...formData, [prop]: value }))
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = { ...formData }
        await fetch(`api/Tracking/GetTrackingDetails`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            },
        }).then(async (response) => {
            const res = await response.json();
            if (res.status === 'SUCCESS') {
              
                setTableData(res)
                navigate(`/Tracking?trackingNumber=${formData.TrackingNo}`);
            } else if (res.status === 'ERROR') {
                setTableData([])
                toast.error(res.message, {
                    position: 'top-center',

                });
            }
        
        })
    }


    useEffect(() => {
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        document.title = 'International Import-Export and Customs Clearance services '
        window.scrollTo(0, 0);
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            setStartCountUp(false)
        };
    }, [navigate]);


    return (

        <>
            <Helmet>
              
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />
                <meta name="description" content="Nihao Logistics is specializes in international import-export or Customs Clearance services and offering seamless logistics solutions for businesses." />
                <meta property="og:title" content="International Import-Export and Customs Clearance services " />
                <meta property="og:site_name" content="Nihao Logistics" />
                <meta property="og:description" content="Nihao Logistics is specializes in international import-export or Customs Clearance services and offering seamless logistics solutions for businesses." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.nihaologistics.com" />
                <meta property="og:image" content="https://www.nihaologistics.com/assets/images/banner-bg1.jpg" />
            </Helmet>
        
            <div className='main-layout'>
            
                <section className="home-section">
                    <div className="container">
                        <div className="home-banner">
                            <div className="row align-items-center w-100">
                                <div className="col-lg-7 col-md-10 mx-auto">
                                    <div className="section-search aos" data-aos="fade-up">
                                        <div className="col-lg-10">
                                            <h1>
                                                {" "}
                                                Welcome to
                                                <span className="red">
                                                    <br /> NIHAO LOGISTICS
                                                </span>
                                            </h1>
                                            <p>
                                                Welcome to our complete Courier Cargo Logistics company, where
                                                we offer a range of logistics services all under one roof. As
                                                the best shipping or import-export company, we take pride in
                                                deploying the unique advantage of 100% online Multi-modal
                                                Transportation.
                                            </p>
                                        </div>
                                        <div className="search-box">
                                            <form action="#">
                                                <div className="search-input">
                                                    <div className="form-group border">
                                                        {/*<input*/}
                                                        {/*    className="form-control"*/}
                                                        {/*    type="text"*/}
                                                        {/*    placeholder="Please Enter AWB Number"*/}
                                                        {/*/>*/}
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder="Please Enter Tracking Number"
                                                            value={formData.TrackingNo}

                                                            onChange={(e) => HandleChange('TrackingNo', e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="search-btn">
                                                    <button className="btn btn-danger" type="submit" onClick={handleSubmit} >
                                                        <i className="fa-solid fa-location-dot me-2" />
                                                        Tracking
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="banner-imgs">
                                        <div className="banner-1 shape-1">
                                            <img
                                                className="img-fluid"
                                                src="images/banner1.png"
                                                alt="air freight"
                                            />
                                        </div>
                                        <div className="banner-2 shape-3">
                                            <img
                                                className="img-fluid"
                                                src="images/banner2.png"
                                                alt="air freight"
                                            />
                                        </div>
                                        <div className="banner-3 shape-3">
                                            <img
                                                className="img-fluid"
                                                src="images/banner3.png"
                                                alt="ground freight"
                                            />
                                        </div>
                                        <div className="banner-4 shape-2">
                                            <img
                                                className="img-responsive"
                                                src="images/banner4.png"
                                                alt="sea freight"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                <div className="about-images">
                                    <div className="mb-0 about-img">
                                        <img
                                            src="images/about-us.png"
                                            className="w-100"
                                            alt="ground freight services"
                                        />
                                        <Link
                                            className="play-btn play-btn-pink"
                                            data-toggle="modal"
                                            data-target="#aboutmodal"
                                        />
                                    </div>
                                    <div className="bnr-bottom"  >
                                        <div className="row aos-init" data-aos="fade-up">

                                            <div className="col-4">
                                                <div className="aboutus-img-box">
                                    <Element name="singleCountUp" className="single-counterup">
                                                  
                                                        <p>Happy Client</p>
                                                        <CountUp start={0} end={100} duration={3} start={startCountUp} />
                                                        <span className="plus">+</span>
                                                    
                                        </Element>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="aboutus-img-box">
                                                   <Element name="singleCountUp" className="single-counterup">
                                                        <p>Packets Delivered</p>
                                                        <CountUp start={0} end={1400} duration={3} start={startCountUp} />
                                                        <span className="plus">+</span>
                                                    </Element>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="aboutus-img-box">
                                                    <Element name="singleCountUp" className="single-counterup">
                                                        <p>Carrier Partners</p>
                                                        <CountUp start={0} end={30} duration={3} start={startCountUp} />
                                                        <span className="plus">+</span>
                                                    </Element>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
                                <div className="heading aos-init aos-animate" data-aos="fade-left">
                                    <div className="line-wrapper position-relative">
                                        <h3>Get to know About Us</h3>
                                    </div>
                                    <p>
                                        Welcome to our complete Courier Cargo Logistics company, where we
                                        offer a range of logistics services all under one roof. As the
                                        best shipping or import-export company, we take pride in deploying
                                        the unique advantage of 100% online Multi-modal Transportation.
                                    </p>
                                    <p>
                                        Our team is dedicated to providing the highest level of service
                                        possible, with a main focus and expertise in moving cargo through
                                        sea freight, ground freight and air freight to various
                                        destinations across the world. We understand the importance of
                                        reliable and efficient transportation, which is why we have
                                        developed an extensive network of transportation providers to
                                        ensure your goods arrive at their destination safely and on time.
                                    </p>
                                    <div className="nihaobtn mt-3 mb-4">
                                        <Link
                                            to="/About"
                                            id="SaveGST"
                                            className="wow fadeInLeft"
                                            style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                        >
                                            Read More
                                            <span>
                                                <img src="images/readmore.png" alt="arrow-right" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="need-help">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-lg-6 col-xl-5 pt-lg-4 mb-5 mb-lg-4">
                                <h2 className="fs-9 text-white text-center fw-bolder mb-7">
                                    Need Help?
                                </h2>
                                <p className="text-white text-center">
                                    Reach out to our Customer Service team for service enquiries, or if
                                    you want to give feedback about your experience with us.
                                </p>
                                <div className="d-sm-flex align-items-center justify-content-center gap-3 text-center">
                                    <div className="stripbtn mt-3 mb-4">
                                        <Link
                                            to="/ContactUs"
                                            className="wow fadeInLeft"
                                            style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                        >
                                            Get in Touch
                                            <span>
                                                <img src="images/readmore.png" alt="arrow-right" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-5 pt-lg-4 mb-5 mb-lg-4">
                                <h2 className="fs-9 text-white text-center fw-bolder mb-7">
                                    Have a Business Enquiry?
                                </h2>
                                <p className="text-white text-center">
                                    Our Sales Representatives are always here to help with corporate or
                                    general sales questions.
                                </p>
                                <div className="d-sm-flex align-items-center justify-content-center gap-3 text-center">
                                    <div className="stripbtn mt-3 mb-4">
                                        <Link
                                            to="/GetQuate"
                                            className="wow fadeInLeft"
                                            style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                        >
                                            Get Quote
                                            <span>
                                                <img src="images/readmore.png" alt="arrow-right" />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our_services_section">
                    <span className="girl-icon mb-0 left_right">
                        <img src="images/ship.png" alt="Girl Icon" />
                    </span>
                    <span className="eye-icon1 mb-0 up_down">
                        <img src="images/eye1.png" alt="Eye Icon" />
                    </span>
                    <span className="eye-icon2 mb-0 up_down1">
                        <img src="images/eye2.png" alt="Eye1 Icon" />
                    </span>
                    <span className="eye-icon3 mb-0 up_down">
                        <img src="images/eye3.png" alt="Eye2 Icon" />
                    </span>
                    <div className="container-fluid">
                        <figure className="mb-0 services_right_background">
                            {" "}
                            <img
                                src="images/our_services_background.webp"
                                alt="The Problem"
                                className="img-fluid"
                            />{" "}
                        </figure>
                        <div className="row aos-init aos-animate" data-aos="fade-up">
                            <div className="col-lg-3 col-md-12 col-sm-12 col-12 d-lg-block d-none">
                                <div className="image_box mb-0 position-relative">
                                    <div className="image_box_content">
                                        <h2 className="text-white">Our Services</h2>
                                        <p className="text-white text-size-16">
                                            Working as an integral part of your team, we have the logistics
                                            industry expertise to support your business and add value across
                                            your supply chain.
                                        </p>
                                        {/*<a class="text-decoration-none default-btn hover-effect readmore_btn" to="#">Know More <i class="fa-solid fa-arrow-right fa-fw"></i></a>*/}
                                        <div className="nihaobtn mt-3 mb-4">
                                            <Link
                                                to="/ContactUs"
                                                id="SaveGST"
                                                className="wow fadeInLeft"
                                                style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                            >
                                                Contact Us
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-12 col-sm-12 col-12">
                                <OwlCarousel
                                    className="owl-theme owl-loaded owl-drag"
                                    loop
                                    margin={10}
                                    autoplay={true}

                                    autoplayTimeout={3000}
                                    responsiveClass={true}
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 2
                                        }
                                    }}
                                    nav
                                >
                                    <div className="owl-stage-outer">
                                        <div
                                            className="owl-stage"
                                            style={{
                                                transform: "translate3d(-1860px, 0px, 0px)",
                                                transition: "all 0.25s ease 0s",
                                                width: 3720
                                            }}
                                        >
                                    <div
                                        className="owl-item"
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item active">
                                            <span className="nmbr">01</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/airplane.png"
                                                        alt="air freight services"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Air Freight</h4>
                                                <div className="arw-listing">
                                                    <p>
                                                        Nihao Logistics &amp; Marketing offers a competitive
                                                        and comprehensive range of international Air...
                                                    </p>
                                                </div>
                                                <div className="btn_wrapper">
                                                    <Link
                                                        className="text-decoration-none"
                                                        to="/airfreightservices"
                                                    >
                                                        Read More{" "}
                                                        <i className="fa-solid fa-arrow-right fa-fw" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="owl-item "
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item">
                                            <span className="nmbr">02</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/freight-shipping.png"
                                                        alt="sea feight service"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Sea Freight</h4>
                                                <div className="arw-listing">
                                                    <p>
                                                        Nihao Logistics &amp; Marketing has developed
                                                        strategic partnerships with major shipping lines to
                                                        ensure...
                                                    </p>
                                                </div>
                                                <div className="btn_wrapper">
                                                    <Link
                                                        className="text-decoration-none"
                                                        to="/seafreightservices"
                                                    >
                                                        Read More{" "}
                                                        <i className="fa-solid fa-arrow-right fa-fw" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="owl-item"
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item">
                                            <span className="nmbr">03</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/delivery.png"
                                                        alt="ground freight services"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Ground Freight</h4>
                                                <div className="arw-listing">
                                                    <p>
                                                        We specialized in regional as well international
                                                        courier and cargo package delivery assure our...
                                                    </p>
                                                </div>
                                                <div className="btn_wrapper">
                                                    <Link
                                                        className="text-decoration-none"
                                                        to="/groundfreightservices"
                                                    >
                                                        Read More{" "}
                                                        <i className="fa-solid fa-arrow-right fa-fw" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="owl-item"
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item">
                                            <span className="nmbr">04</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/rack.png"
                                                        alt="warehousing services"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Warehousing Service</h4>
                                                <div className="arw-listing">
                                                    <p>
                                                        Our organization offers a warehousing service in India
                                                        for both industrial and household items.
                                                    </p>
                                                </div>
                                                <div className="btn_wrapper">
                                                    <Link
                                                        className="text-decoration-none"
                                                        to="/warehousingservices"
                                                    >
                                                        Read More{" "}
                                                        <i className="fa-solid fa-arrow-right fa-fw" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="owl-item"
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item">
                                            <span className="nmbr">05</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/custom-clearance.png"
                                                        alt="custom clearance"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Customs Clearance</h4>
                                                <div className="arw-listing">
                                                    <p>
                                                        As a global freight forwarder, our company places
                                                        great importance on the customs clearance process.
                                                    </p>
                                                </div>
                                                <div className="btn_wrapper">
                                                    <Link
                                                        className="text-decoration-none"
                                                        to="/customclearanceservices"
                                                    >
                                                        Read More{" "}
                                                        <i className="fa-solid fa-arrow-right fa-fw" />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="owl-item"
                                        style={{ width: 342, marginRight: 30 }}
                                    >
                                        <div className="item">
                                            <span className="nmbr">06</span>
                                            <div className="box mb-0">
                                                <figure>
                                                    {" "}
                                                    <img
                                                        src="images/import.png"
                                                        alt="import & export"
                                                        className="img-fluid"
                                                    />{" "}
                                                </figure>
                                                <h4>Import &amp; Export</h4>
                                                        <div className="arw-listing">
                                                            <p>
                                                                At Nihao Logistics, we offer comprehensive import and export services,
                                                                with the goal of delivering end-to-end support to our clients.
                                                            </p>
                                                        </div>
                                                        <div className="btn_wrapper">
                                                            <a className="text-decoration-none" href="import-export-services.html">
                                                                Read More <i className="fa-solid fa-arrow-right fa-fw" />
                                                            </a>
                                                        </div>
                                               
                                            
                                        </div>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>

                            </div>
                        </div>
                    </div>
                </section>
                <section className="prvdr-warea">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 aos-init aos-animate" data-aos="zoom-in">
                                <h3>Experience the Best Service with us</h3>
                                <p>
                                    Reach out to a more diverse clientele, get digitally empowered, and
                                    explore the Nihao Logistics. Joining us for free - no upfront or
                                    hidden charges involved.
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Link to="#">
                                            <div className="prvdr-box">
                                                <span className="prvdr-icon">
                                                    <img src="images/24-hours.png" alt="24/7 support" />
                                                </span>
                                                <div className="cntn-area1">
                                                    <h4>24/7 SUPPORT</h4>
                                                </div>
                                                <p>
                                                    We offer various logistic management services and supply
                                                    chain solutions for our clients 24*7.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prvdr-box">
                                            <span className="prvdr-icon">
                                                <img src="images/global-services.png" alt="global service" />
                                            </span>
                                            <div className="cntn-area1">
                                                <h4>GLOBAL SERVICE</h4>
                                            </div>
                                            <p>
                                                We seize opportunities as they arise and make the best market
                                                for you globally.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prvdr-box">
                                            <span className="prvdr-icon">
                                                <img src="images/delivery-time.png" alt="on time delivery" />
                                            </span>
                                            <div className="cntn-area1">
                                                <h4>ON TIME DELIVERY</h4>
                                            </div>
                                            <p>
                                                We are committed to provide quick delivery in a day or two
                                                days.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="prvdr-box">
                                            <span className="prvdr-icon">
                                                <img src="images/high-level.png" alt="cold chain" />
                                            </span>
                                            <div className="cntn-area1">
                                                <h4>HIGH-LEVEL OF TRANSPARENCY</h4>
                                            </div>
                                            <p>
                                                Every parcel is tracked, Full control over your parcel
                                                delivery.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="nihaobtn mt-3 mb-4">
                                    <Link
                                        to="#"
                                        id="SaveGST"
                                        className="wow fadeInLeft"
                                        style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                    >
                                        Learn More
                                        <span>
                                            <img src="images/readmore.png" alt="arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-5 aos-init aos-animate" data-aos="fade-up">
                                <img
                                    src="images/banner-image.jpg"
                                    alt="air cargo services"
                                    className="w-100 prvdr-img"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="become-franchise">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <div className="become-franchise-text text-dark">
                                    <h4>
                                        Contact us now to get quote for all your global shipping needs.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-lg-3 mt-3 mt-sm-0">
                                <div className="whitebtn">
                                    <Link
                                        to="/ContactUs"
                                        id="SaveGST"
                                        className="wow fadeInLeft"
                                        style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                    >
                                        Contact us
                                        <span>
                                            <img src="images/readmore2.png" alt="arrow-right" />
                                        </span>
                                    </Link>
                                </div>
                                {/*<a to="#" class="header-button">Contact us</a>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <section className="why-choose">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2>Why People Choose Us?</h2>
                                <p>We Are Provide The Our Best Services</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="why-choose-box">
                                    <div className="why-icon">
                                        <img src="images/Fast-Delivery.png" alt="Fast Delivery" />
                                        <h5>Fast Delivery</h5>
                                    </div>
                                    <p className="text-justify">
                                        We provide very fast delivery of consignment to the clients within
                                        given time.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="why-choose-box">
                                    <div className="why-icon">
                                        <img src="images/Best-Logistics.png" alt="Best Logistics" />
                                        <h5>Best Logistics</h5>
                                    </div>
                                    <p className="text-justify">
                                        We provide logistics and cargo services at most economical rates
                                        with safe and secure movement.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="why-choose-box">
                                    <div className="why-icon">
                                        <img src="images/Delivery-Anywhere.png" alt="Delivery Anywhere" />
                                        <h5>Delivery Anywhere</h5>
                                    </div>
                                    <p className="text-justify">
                                        We provide delivery of air consignments on economical and
                                        competitive rates.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="col-warea">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-box">
                                    <div className="headline">
                                        <h3> Connect With Us</h3>
                                    </div>
                                    <div />
                                    <div className="button_content">
                                        <div className="row">
                                            <div className="button-group">
                                                <Link
                                                    to="tel:+919971132304"
                                                    className="button work_Us animate__animated animate__fadeInLeft"
                                                >
                                                    <i className="fab fa-whatsapp" /> &nbsp;Call Us Now
                                                    <span className="subtitle" />
                                                </Link>
                                                <span className="or">or</span>
                                                <Link
                                                    to="/ContactUs"
                                                    className="button work_Us animate__animated animate__fadeInRight"
                                                >
                                                    <i className="fa-solid fa-file-signature" /> &nbsp;Write To
                                                    Us
                                                    <span className="subtitle" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div
                    className="modal fade modal-start-project"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    id="btnCloseModal"
                                    className="btn-close close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="flex-row">
                                    <div className="col modal-left-col modal-info">
                                        <div className="highlighted-serivces">
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/delivery.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Safe &amp; Secure Delivery</h5>
                                                    <p>
                                                        A higher level of competence, using state-of-the-art
                                                        software and experienced professionals to improve overall
                                                        safety and security for parcel.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/save-money.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Cost Savings</h5>
                                                    <p>
                                                        Effective instruments, which aim to control cost by
                                                        harnessing the power of operational optimization, offer a
                                                        more cost-effective, flexible and dynamic solution of
                                                        delivering on time than conventional measures.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/optimization.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Transparency</h5>
                                                    <p>Service Price Calculater-What You See is What is Pay.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/deadline.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Assurance</h5>
                                                    <p>
                                                        Continued Promise of Extended Warranty &amp; Part
                                                        Availability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col modal-right-col modal-form">
                                        <div className="content-inner">
                                            <div className="form-headingtext">
                                                <span className="title-label">Connect with us</span>
                                                <h2>
                                                    Get <span>No-Cost Quote</span> and Expert Consultation
                                                </h2>
                                            </div>
                                            <div className="contact-form project-form">
                                                <div
                                                    className="wpcf7 js"
                                                    id="wpcf7-f36355-o1"
                                                    lang="en-US"
                                                    dir="ltr"
                                                >
                                                    <div className="screen-reader-response">
                                                        <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                        <ul />
                                                    </div>
                                                    <form
                                                        action="#"
                                                        method="post"
                                                        className="wpcf7-form init"
                                                        aria-label="Contact form"
                                                    >
                                                        <div className="form-group">
                                                            <p>
                                                                <label>Name*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-name"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="name"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="your-name"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Email*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-email"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="email"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="email"
                                                                        name="your-email"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Company/Organization </label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="company-organization"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="company-organization"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> How can we help you?*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-message"
                                                                >
                                                                    <textarea
                                                                        cols={40}
                                                                        rows={10}
                                                                        className="form-control"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        name="your-message"
                                                                        defaultValue={""}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-1"
                                                                    id="chkIagree"
                                                                />
                                                                I confirm that I have read and understood the Terms of
                                                                Service and Privacy Policy *
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="nihaobtn mt-3 mb-4">
                                                                <Link
                                                                    to="#"
                                                                    id="SaveBTN"
                                                                    className="wow fadeInLeft"
                                                                    style={{
                                                                        visibility: "visible",
                                                                        animationName: "fadeInLeft"
                                                                    }}
                                                                >
                                                                    Submit
                                                                    <span>
                                                                        <img
                                                                            src="images/readmore.png"
                                                                            alt="arrow-right"
                                                                        />
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="wpcf7-response-output"
                                                            aria-hidden="true"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Home
