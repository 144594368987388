import { ToastContainer } from 'react-toastify';

import Home from "./components/Home";
import Layout from "./components/Layout";
import NavMenu from "./components/NavMenu";
import './custom.css'
import { Route, Routes } from 'react-router-dom';
import AppRoutes from "./AppRoutes";
import About from "./components/About";
import Kyc from "./components/Kyc";
import GetQuate from "./components/GetQuate";
import ContactUs from "./components/ContactUs";
import AirfreightServices from "./components/Servicepages/AirfreightServices";
import SeafreightServices from "./components/Servicepages/SeaFreightServices";
import GroundFreight from "./components/Servicepages/GroundFreight";
import WareHouse from "./components/Servicepages/Warehousing";
import CustomClearance from "./components/Servicepages/CustomClearance";
import ImportExport from "./components/Servicepages/ImportExport";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TearmAndConditions from "./components/TearmAndConditions";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from 'react';
import ScrollToTopButton from './components/CommanComponent/ScrolltoTop';
import Tracking from './components/Tracking';
import ImageView from './components/ImageView';
import PDFView from './components/PDFView';
import Footer from './components/Footer';


function App ()  {
    
    return (
        <>
            <div className='main-layout'>
            <section className="content-cover">

                    <ToastContainer position={'top-center'} />
                    <Layout>
                        <Routes>
                    <Route path='/Images' element={<ImageView />} />
                            <Route path='/PdfView' element={<PDFView />} />
                        </Routes>

                    </Layout>
            <NavMenu />
            <Layout>
                <Routes>

                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}
                    <Route path='/' element={<Home />} />
                    <Route path='/About' element={<About />} />
                            <Route path='/Kyc' element={<Kyc />} />
                            <Route path='/GetQuate' element={<GetQuate />} />
                           
                    <Route path='/ContactUs' element={<ContactUs />} />
                    <Route path='/airfreightservices' element={<AirfreightServices />} />
                    <Route path='/seafreightservices' element={<SeafreightServices />} />
                    <Route path='/groundfreightservices' element={<GroundFreight />} />
                    <Route path='/warehousingservices' element={<WareHouse />} />
                    <Route path='/customclearanceservices' element={<CustomClearance />} />
                    <Route path='/importexportservices' element={<ImportExport />} />
                    <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                            <Route path='/TermAndCondition' element={<TearmAndConditions />} />
                            <Route path='/Tracking' element={<Tracking />} />
                           


                </Routes>
              
                    </Layout>
                    <ScrollToTopButton />
                    <Footer/>
                </section>
                </div>
        </>
    );
}
export default App

