import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';


const NavMenu = ({ }) => {
    const [activeMenu, setactiveMenu] = useState(null);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        console.log(toggleMenu,"gfsdggsdgs")
    }
    const handleMenuClick = (index) => {
        setactiveMenu(activeMenu === index ? null : index);
    };
    const closeMenu = () => {
        setMenuOpen(false);
    };
    const [isKycActive, setIsKycActive] = useState(false);

    const handleKycClick = () => {
        setIsKycActive(true);
    };


    const menuItem = [
        {
            path: '/Home',
            name: 'Home',
        },
        {
            path: '/About',
            name: 'About',
        },
        {
            path: '/services',
            name: 'Services',
            submenu: [
                { path: '/airfreightservices', name: 'Air Freight' },
                { path: '/seafreightservices', name: 'Sea Freight' },
                { path: '/groundfreightservices', name: 'Ground Freight' },
                { path: '/warehousingservices', name: 'Warehousing' },
                { path: '/customclearanceservices', name: 'Custom Clearance' },
                { path: '/importexportservices', name: 'Import Export' },
            ],
        },
        {
            path: 'http://app.nihaologistics.com/',
            name: 'Admin Login',
           
        },
        {
            path: '/Kyc',
            name: 'Kyc',
        },
       
        {
            path: '/ContactUs',
            name: 'Contact Us',
        },
        {
            path: `/Tracking?trackingNumber=''`,
            name: 'Tracking',
            isButton: true,
        },
    ];

    return (
        <header className="header">
            <div className="container">
                <nav className="navbar navbar-expand-lg header-nav">
                    <div className="navbar-header">
                        <a id="mobile_btn" href="#" onClick={toggleMenu}>
                            <span className="bar-icon">
                                <span />
                                <span />
                                <span />
                            </span>
                        </a>
                        <a href="/" className="navbar-brand logo">
                            <img src="images/nihao-logo.png" alt="Nihao-Logistics & Marketing" />
                        </a>
                        <a href="/" className="navbar-brand logo-small">
                            <img src="images/nihao-logo.png" alt="Nihao-Logistics & Marketing" />
                        </a>
                    </div>
                    <div className={`main-menu-wrapper ${isMenuOpen ? 'open' : ''}`}>
                        <div className="menu-header">
                            <a href="#" className="menu-logo">
                                <img
                                    src="images/nihao-logo.png"
                                    alt="Nihao-Logistics & Marketing"
                                />
                            </a>
                            <a id="menu_close" className="menu-close" onClick={closeMenu} >
                                <i className="fas fa-times" />
                            </a>
                        </div>
                        <ul className='main-nav'>
                            {menuItem.map((item, index) => (
                                <li key={index} className={item.submenu ? 'has-submenu' : ''} >
                                    {item.submenu ? (
                                        <>
                                            <a href="javascript:void(0);" onClick={() => handleMenuClick(index)}>
                                                {item.name} <i className="fas fa-chevron-down" />
                                            </a>
                                         
                                            <ul className={`submenu ${activeMenu === index ? 'active' : ''}`}>
                                                {item.submenu.map((subitem, subindex) => (
                                                    <li key={subindex} >
                                                            <NavLink to={subitem.path} activeClassName="active">
                                                                {subitem.name}
                                                            </NavLink>
                                                       
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : (
                                            <li>

                                                {item.isButton ? (
                                                    <button className={`nav-button is-button-true`} onClick={closeMenu} >
                                                      
                                                        <span> <Link to="/Tracking">Tracking
                                                            <i className="fa-solid fa-location-dot me-2 ms-2" />
                                                      
                                                        </Link>
                                                        </span>
                                                    </button>
                                            ) : (
                                                        <NavLink
                                                            to={item.path}
                                                            exact
                                                            activeClassName={(isKycActive && item.path === '/Kyc') ? 'active' : ''}
                                                            onClick={item.path === '/Kyc' ? handleKycClick : undefined}
                                                            target={item.path.startsWith('http') ? '_blank' : '_self'}>
                                                            {item.name}
                                                        </NavLink>
                                            )}
                                        </li>
                                    )}
                                </li>
                            ))}
                        </ul>
                        
                    </div>
                </nav>
            </div>
        </header>
       
    );
};

export default NavMenu;
