import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
function TearmAndConditions() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [navigate]);
    return (
        <>
            <section className="terms-conditions">
                <div className="terms-title">
                    <h1>
                        Terms<span className="red"> Conditions</span>
                    </h1>
                    <hr className="bottom-border" />
                </div>
                <div className="container">
                    <div className="terms">
                        <ul>
                            <li>Quoted charges are inclusive of 18% GST.</li>
                            <li>
                                The Chargeable weight would be volumetric or actual weight,
                                whichever is higher (Air/ Rush mode: LxBxH/ 5000 | LITE/ Surface
                                mode: LxBxH/ 4000).
                            </li>
                            <li>
                                The maximum liability for forward shipping is ?5000/- or invoice
                                value whichever is lower.
                            </li>
                            <li>
                                The maximum liability for reverse shipping is ? 2000/- or 50% of the
                                invoice value whichever is lower.
                            </li>
                            <li>
                                No Claim would be entertained for Glassware, Fragile products,
                                Concealed damages, and improper packaging.
                            </li>
                            <li>Manual label penalty is ?500/- per shipment.</li>
                            <li>
                                Billing disputes must be escalated within 5 days from the invoice
                                date.
                            </li>
                            <li>
                                Weight dispute due to incorrect weight declaration cannot be
                                claimed.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <div
                className="modal fade modal-start-project"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="btnCloseModal"
                                className="btn-close close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="flex-row">
                                <div className="col modal-left-col modal-info">
                                    <div className="highlighted-serivces">
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/delivery.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Safe &amp; Secure Delivery</h5>
                                                <p>
                                                    A higher level of competence, using state-of-the-art
                                                    software and experienced professionals to improve overall
                                                    safety and security for parcel.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/save-money.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Cost Savings</h5>
                                                <p>
                                                    Effective instruments, which aim to control cost by
                                                    harnessing the power of operational optimization, offer a
                                                    more cost-effective, flexible and dynamic solution of
                                                    delivering on time than conventional measures.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/optimization.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Transparency</h5>
                                                <p>Service Price Calculater-What You See is What is Pay.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/deadline.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Assurance</h5>
                                                <p>
                                                    Continued Promise of Extended Warranty &amp; Part
                                                    Availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col modal-right-col modal-form">
                                    <div className="content-inner">
                                        <div className="form-headingtext">
                                            <span className="title-label">Connect with us</span>
                                            <h2>
                                                Get <span>No-Cost Quote</span> and Expert Consultation
                                            </h2>
                                        </div>
                                        <div className="contact-form project-form">
                                            <div
                                                className="wpcf7 js"
                                                id="wpcf7-f36355-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                    <ul />
                                                </div>
                                                <form
                                                    action="#"
                                                    method="post"
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                >
                                                    <div className="form-group">
                                                        <p>
                                                            <label>Name*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Email*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Company/Organization </label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="company-organization"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="company-organization"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> How can we help you?*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    name="your-message"
                                                                    defaultValue={""}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                id="chkIagree"
                                                            />
                                                            I confirm that I have read and understood the Terms of
                                                            Service and Privacy Policy *
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <a
                                                                href="#"
                                                                id="SaveBTN"
                                                                className="wow fadeInLeft"
                                                                style={{
                                                                    visibility: "visible",
                                                                    animationName: "fadeInLeft"
                                                                }}
                                                            >
                                                                Submit
                                                                <span>
                                                                    <img
                                                                        src="images/readmore.png"
                                                                        alt="arrow-right"
                                                                    />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back_top" onclick="topFunction()" id="myBtn">
                <i className="fa fa-long-arrow-alt-right" />
            </div>
        </>

           

    )
}
export default TearmAndConditions