import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function GetQuate() {
    useEffect(() => {

        document.title = 'Get Quotation with Nihao Logistics'
    }, [])
    return (
        <>
            <Helmet>
                <title>Get Quotation with Nihao Logistics </title>
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                <meta name="description" content="You can request a quote right away by filling out the quote form. We will respond to you as soon as possible." />
                <meta property="og:title" content="Get Quotation with Nihao Logistics" />
                <meta property="og:description" content="You can request a quote right away by filling out the quote form. We will respond to you as soon as possible." />
                <meta property="og:url" content="https://www.nihaologistics.com/get-quote" />
            </Helmet>
            <section className="home-section">
                <div className="container">
                    <div className="quote-banner">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <div className="quote-content">
                                    <div className="col-lg-10">
                                        <h1>
                                            Get<span className="red"> Quote</span>
                                        </h1>
                                        <h6>Don't hesitate to Get Quotation</h6>
                                        <p>
                                            Please fill out the form to request a quote, We will get back
                                            to you as soon as possible.
                                        </p>
                                        <div className="nihaobtn mt-3 mb-4">
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                id="SaveBTN"
                                                className="wow fadeInLeft"
                                                style={{
                                                    visibility: "visible",
                                                    animationName: "fadeInLeft"
                                                }}
                                            >
                                                Get a Free Quote
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="quote-right-banner">
                                    <img
                                        src="images/getquate.png"
                                        className="img-fluid"
                                        alt="get quote"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="get-quote">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-form p-3 ">
                                <p>All marked fields (*) are mandatory.</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input type="hidden" id="HdnGetQuoteID" />
                                        <div className="form-group">
                                            <label className="form-label">
                                                Origin Location<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtOrigin"
                                                className="form-control"
                                                placeholder="Origin Location"
                                                maxLength={30}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Origin Zip Code
                                                <span className="text-danger" />
                                            </label>
                                            <input
                                                type="text"
                                                id="txtZipcode"
                                                className="form-control"
                                                placeholder="Origin Zip Code"
                                                maxLength={6}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Destination Location<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtDestination"
                                                className="form-control"
                                                placeholder="Destination Location"
                                                autoComplete="off"
                                                maxLength={30}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Destination Zip Code
                                                <span className="text-danger" />
                                            </label>
                                            <input
                                                type="text"
                                                id="DestinationZipcode"
                                                className="form-control"
                                                placeholder="Destination Zip Code"
                                                maxLength={6}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Sender Name<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtName"
                                                className="form-control"
                                                placeholder="Sender Name"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Sender Address<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtAddress"
                                                className="form-control"
                                                placeholder="Sender Address"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Mobile No.<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtMobile"
                                                className="form-control"
                                                placeholder="Mobile No."
                                                maxLength={10}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Email ID<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                id="txtEmail"
                                                className="form-control"
                                                placeholder="Email ID"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Date</label>
                                            <input
                                                id="txtDate"
                                                className="form-control hasDatepicker"
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Time</label>
                                            <input
                                                type="time"
                                                id="txtTime"
                                                className="form-control"
                                                placeholder="Time"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Product<span className="text-danger">*</span>
                                            </label>
                                            <select className="form-select" id="ddlProduct">
                                                <option value="DOX">DOX</option>
                                                <option value="SPX">SPX</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Pieces<span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                id="txtPieces"
                                                className="form-control text-right"
                                                placeholder="Pieces"
                                                maxLength={15}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label">
                                                Weight<span className="text-danger">*</span>
                                            </label>
                                            <select className="form-select" id="ddlWeight">
                                                <option>KGS</option>
                                                <option>LBS</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label">Length</label>
                                            <input
                                                type="text"
                                                id="txtLenth"
                                                className="form-control text-right"
                                                maxLength={18}
                                                placeholder="Cm"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label">Width</label>
                                            <input
                                                type="text"
                                                id="txtWidth"
                                                className="form-control text-right"
                                                maxLength={18}
                                                placeholder="Cm"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 col-md-3">
                                        <div className="form-group">
                                            <label className="form-label">Height</label>
                                            <input
                                                type="text"
                                                id="txtHeight"
                                                className="form-control text-right"
                                                maxLength={18}
                                                placeholder="Cm"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Actual Weight</label>
                                            <input
                                                type="text"
                                                id="txtActualWeight"
                                                className="form-control text-right"
                                                placeholder="Actual Weight"
                                                maxLength={18}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Volumetric Weight</label>
                                            <input
                                                type="text"
                                                id="txtVolumeWeight"
                                                className="form-control text-right"
                                                maxLength={18}
                                                readOnly="readonly"
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="form-label">Charged Weight</label>
                                            <input
                                                type="text"
                                                id="txtChargeWeight"
                                                readOnly="readonly"
                                                className="form-control text-right"
                                                maxLength={18}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="nihaobtn mt-3 mb-4">
                                            <a
                                                href="#"
                                                id="SaveBTN"
                                                className="wow fadeInLeft"
                                                style={{
                                                    visibility: "visible",
                                                    animationName: "fadeInLeft"
                                                }}
                                            >
                                                Get Quote
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div
                className="modal fade modal-start-project"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="btnCloseModal"
                                className="btn-close close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="flex-row">
                                <div className="col modal-left-col modal-info">
                                    <div className="highlighted-serivces">
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/delivery.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Safe &amp; Secure Delivery</h5>
                                                <p>
                                                    A higher level of competence, using state-of-the-art
                                                    software and experienced professionals to improve overall
                                                    safety and security for parcel.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/save-money.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Cost Savings</h5>
                                                <p>
                                                    Effective instruments, which aim to control cost by
                                                    harnessing the power of operational optimization, offer a
                                                    more cost-effective, flexible and dynamic solution of
                                                    delivering on time than conventional measures.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/optimization.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Transparency</h5>
                                                <p>Service Price Calculater-What You See is What is Pay.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/deadline.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Assurance</h5>
                                                <p>
                                                    Continued Promise of Extended Warranty &amp; Part
                                                    Availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col modal-right-col modal-form">
                                    <div className="content-inner">
                                        <div className="form-headingtext">
                                            <span className="title-label">Connect with us</span>
                                            <h2>
                                                Get <span>No-Cost Quote</span> and Expert Consultation
                                            </h2>
                                        </div>
                                        <div className="contact-form project-form">
                                            <div
                                                className="wpcf7 js"
                                                id="wpcf7-f36355-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                    <ul />
                                                </div>
                                                <form
                                                    action="#"
                                                    method="post"
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                >
                                                    <div className="form-group">
                                                        <p>
                                                            <label>Name*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Email*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Company/Organization </label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="company-organization"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="company-organization"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> How can we help you?*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    name="your-message"
                                                                    defaultValue={""}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                id="chkIagree"
                                                            />
                                                            I confirm that I have read and understood the Terms of
                                                            Service and Privacy Policy *
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <a
                                                                href="#"
                                                                id="SaveBTN"
                                                                className="wow fadeInLeft"
                                                                style={{
                                                                    visibility: "visible",
                                                                    animationName: "fadeInLeft"
                                                                }}
                                                            >
                                                                Submit
                                                                <span>
                                                                    <img
                                                                        src="images/readmore.png"
                                                                        alt="arrow-right"
                                                                    />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back_top" onclick="topFunction()" id="myBtn">
                <i className="fa fa-long-arrow-alt-right" />
            </div>
        </>

    )
}
export default GetQuate