import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


//function About() {
//    useEffect(() => {

//        document.title = 'About Us | Trusted Partner forImport or Export Services| Nihao Logistics'
//}, [])
function About() {
    const navigate = useNavigate();
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    useEffect(() => {
        document.title = 'About Us | Trusted Partner for Import or Export Services | Nihao Logistics';
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);
    return (

        <>
            <>
                <Helmet>
                    <title>About Us | Trusted Partner forImport or Export Services| Nihao Logistics </title>
                    <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                    <meta name="description" content="Your trusted provider of the best import and export services. Discover our expertise in seamless logistics solutions. Learn more about us." />
                    <meta property="og:title" content="About Us | Trusted Partner forImport or Export Services| Nihao Logistics" />
                    <meta property="og:description" content="Your trusted provider of the best import and export services. Discover our expertise in seamless logistics solutions. Learn more about us." />
                    <meta property="og:url" content="https://www.nihaologistics.com/about-us " />
                </Helmet>
                <section className="home-section">
                    <div className="container">
                        <div className="about-banner">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-10 mx-auto">
                                    <div className="about-content">
                                        <div className="col-lg-10">
                                            <h1>
                                                About<span className="red"> Us</span>
                                            </h1>
                                            <p>
                                                Welcome to our complete Courier Cargo Logistics company, where
                                                we offer a range of logistics services all under one roof. As
                                                the best shipping or import-export company, we take pride in
                                                deploying the unique advantage of 100% online Multi-modal
                                                Transportation.
                                            </p>
                                            <div className="nihaobtn mt-3 mb-4">
                                                <Link
                                                    to="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                    id="SaveBTN"
                                                    className="wow fadeInLeft"
                                                    style={{
                                                        visibility: "visible",
                                                        animationName: "fadeInLeft"
                                                    }}
                                                >
                                                    Get a Free Quote
                                                    <span>
                                                        <img src="images/readmore.png" alt="arrow-right" />
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="right-banner">
                                        <img
                                            src="images/aboutus.png"
                                            className="img-fluid"
                                            alt="about us"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="clientele">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="heading mb-1">
                                <h2>Integrated logistics and supply chain solutions</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <OwlCarousel
                                    className="owl-theme owl-crousal"
                                    loop={true}
                                    margin={20}
                                    autoplay={true}
                                    autoplayTimeout={3000}
                                    nav={false}
                                    dots={false}
                                    center={true}
                                    autoplaySpeed={2000}
                                    responsiveClass={true}
                                    responsive={{
                                        0: {
                                            items: 1
                                        },
                                        600: {
                                            items: 3
                                        },
                                        1000: {
                                            items: 5
                                        }
                                    }}
                                >
                                    <div className="item">
                                        <img src="images/client-logo/air.jpg" alt="air freight" />
                                        <h4>Air Freight</h4>
                                    </div>
                                    <div className="item">
                                        <img src="images/client-logo/sea.jpg" alt="sea freight" />
                                        <h4>Sea Freight</h4>
                                    </div>
                                    <div className="item">
                                        <img src="images/client-logo/trans.jpg" alt="transportation" />
                                        <h4>Transportation</h4>
                                    </div>
                                    <div className="item">
                                        <img
                                            src="images/client-logo/project.jpg"
                                            alt="project logistics"
                                        />
                                        <h4>Project Logistics &amp;</h4>
                                    </div>
                                    <div className="item">
                                        <img src="images/client-logo/last.jpg" alt="last mile delivery" />
                                        <h4>Last Mile Delivery</h4>
                                    </div>
                                    <div className="item">
                                        <img
                                            src="images/client-logo/custom.jpg"
                                            alt="customs brokerage"
                                        />
                                        <h4>Customs Brokerage</h4>
                                    </div>
                                    {/*<div class="item">
                  <img src="images/client-logo/cater.jpg">
                  <h4>Chartering Services</h4>
              </div>*/}
                                    <div className="item">
                                        <img
                                            src="images/client-logo/whouse.jpg"
                                            alt="warehousing & distribution"
                                        />
                                        <h4>Warehousing &amp; Distribution</h4>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <div className="about-group-image d-flex flex-wrap align-items-start flex-column">
                                    <div className="image-box">
                                        <img
                                            className="p-z-idex img-fluid"
                                            src="images/ab-1.jpg"
                                            alt="nihao logistics"
                                        />
                                    </div>
                                    <img
                                        className="group-1 p-z-idex img-fluid"
                                        src="images/ab-2.jpg"
                                        alt="nihao logistics"
                                    />
                                    <img
                                        className="group-shape img-fluid"
                                        src="images/ab-shape.png"
                                        alt="nihao logistics"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-content">
                                    <h2>Nihao Logistics</h2>
                                    <p>
                                        Nihao Logistics has global partnerships with reputed agents in all
                                        major countries, and is committed to provide safe, reliable and
                                        efficient services to its customers and overseas partners.
                                    </p>
                                    <p>
                                        These services also include the necessary storage facilities and
                                        local collection and delivery services at either end of the
                                        movement.
                                    </p>
                                    <p>
                                        Our target is to set up benchmarks in the domain by offering
                                        Freight forwarding solutions, warehousing, air freight, import
                                        export, services which are reliable and at the same time cost
                                        effective. We regard our clients as the ‘Prized Possession’ of our
                                        company and hence, use advanced technologies to fulfill all their
                                        domestic and international transportation needs through an
                                        innovative and methodological approach.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="features-section">
                    <div className="bg bg-image" />
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="main-title mb-5">Mission, Vision and Values</div>
                            </div>
                            <div
                                className="feature-block col-xl-4 col-md-6 wow fadeInUp animated"
                                style={{ visibility: "visible", animationName: "fadeInUp" }}
                            >
                                <div className="inner-box">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img
                                                src="images/mission.png"
                                                className="img-fluid"
                                                alt="our mission"
                                            />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            <Link to="#">Our Mission</Link>
                                        </h6>
                                        <div className="text">
                                            Providing a cost effective specialist freight forwarding service
                                            designed for working in partnership with our individual
                                            customers
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="feature-block col-xl-4 col-md-6 wow fadeInUp animated"
                                data-wow-delay="300ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "300ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <div className="inner-box">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img
                                                src="images/vision.png"
                                                className="img-fluid"
                                                alt="our vision"
                                            />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            <Link to="#">Our Vision</Link>
                                        </h6>
                                        <div className="text">
                                            Our vision is to assemble the perfect team and be the catalyst
                                            to inspire, incite and augment all parties for un-paralleled
                                            success.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="feature-block col-xl-4 col-md-6 wow fadeInUp animated"
                                data-wow-delay="600ms"
                                style={{
                                    visibility: "visible",
                                    animationDelay: "600ms",
                                    animationName: "fadeInUp"
                                }}
                            >
                                <div className="inner-box">
                                    <div className="icon-box">
                                        <div className="icon">
                                            <img
                                                src="images/value.png"
                                                className="img-fluid"
                                                alt="our value"
                                            />
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">
                                            <Link to="#">Our Values</Link>
                                        </h6>
                                        <div className="text">
                                            Nihao Logistics is a logistics solution provider that will unite
                                            your logistics platforms and elevate efficiency and
                                            productivity.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="why-choose-us">
                    <div className="icon-dots-4" />
                    <div className="container">
                        <div className="outer-box">
                            <div className="row g-0">
                                <div className="content-column col-lg-6 order-lg-2">
                                    <div
                                        className="inner-column wow fadeInLeft animated"
                                        style={{ visibility: "visible", animationName: "fadeInLeft" }}
                                    >
                                        <div className="sec-title">
                                            <h2 className="words-slide-up text-split">Why Choose Us</h2>
                                            <div className="text">
                                                We carry all the activities involved in a freight forwarding
                                                business such as transportation, warehousing, packaging and
                                                loading or unloading in the best possible manner using modern
                                                technology to offer the best services to clients.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="features-column col-lg-6">
                                    <div className="inner-column">
                                        <div
                                            className="bg bg-image"
                                            style={{ backgroundImage: "url(images/2.jpg)" }}
                                        />
                                        {/*<div class="title-box">
                      <h2 class="title words-slide-up text-split">Creating tailor made solutions that work for you</h2>
                  </div>*/}
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/worldwide.png"
                                                            className="img-fluid"
                                                            alt="world wide transport"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">World Wide Transport</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/cruise.png"
                                                            className="img-fluid"
                                                            alt="ship transport"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Ship Transport</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/b2c.png"
                                                            className="img-fluid"
                                                            alt="logistic strategies"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Logistic Strategies</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/box.png"
                                                            className="img-fluid"
                                                            alt="secure packaging"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Secure Packaging</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/shipping.png"
                                                            className="img-fluid"
                                                            alt="trusted shipping methods"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Trusted Shipping Methods</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/warehousing.png"
                                                            className="img-fluid"
                                                            alt="logistic warehousing"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Logistic Warehousing</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/custom.png"
                                                            className="img-fluid"
                                                            alt="custom clearance"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Custom Clearance</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="feature-block-five">
                                            <div className="inner-box">
                                                <div className="icon-box">
                                                    <div className="icon">
                                                        <img
                                                            src="images/icons/fast-delivery.png"
                                                            className="img-fluid"
                                                            alt="fast delivery attempts"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="content-box">
                                                    <h6 className="title">Fast Delivery Attempts</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div
                    className="modal fade modal-start-project"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    id="btnCloseModal"
                                    className="btn-close close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="flex-row">
                                    <div className="col modal-left-col modal-info">
                                        <div className="highlighted-serivces">
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/delivery.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Safe &amp; Secure Delivery</h5>
                                                    <p>
                                                        A higher level of competence, using state-of-the-art
                                                        software and experienced professionals to improve overall
                                                        safety and security for parcel.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/save-money.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Cost Savings</h5>
                                                    <p>
                                                        Effective instruments, which aim to control cost by
                                                        harnessing the power of operational optimization, offer a
                                                        more cost-effective, flexible and dynamic solution of
                                                        delivering on time than conventional measures.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/optimization.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Transparency</h5>
                                                    <p>Service Price Calculater-What You See is What is Pay.</p>
                                                </div>
                                            </div>
                                            <div className="item">
                                                <div className="icon">
                                                    <img src="images/icons/deadline.png" />
                                                </div>
                                                <div className="content">
                                                    <h5>Assurance</h5>
                                                    <p>
                                                        Continued Promise of Extended Warranty &amp; Part
                                                        Availability.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col modal-right-col modal-form">
                                        <div className="content-inner">
                                            <div className="form-headingtext">
                                                <span className="title-label">Connect with us</span>
                                                <h2>
                                                    Get <span>No-Cost Quote</span> and Expert Consultation
                                                </h2>
                                            </div>
                                            <div className="contact-form project-form">
                                                <div
                                                    className="wpcf7 js"
                                                    id="wpcf7-f36355-o1"
                                                    lang="en-US"
                                                    dir="ltr"
                                                >
                                                    <div className="screen-reader-response">
                                                        <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                        <ul />
                                                    </div>
                                                    <form
                                                        action="#"
                                                        method="post"
                                                        className="wpcf7-form init"
                                                        aria-label="Contact form"
                                                    >
                                                        <div className="form-group">
                                                            <p>
                                                                <label>Name*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-name"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="name"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="your-name"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Email*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-email"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        autoComplete="email"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="email"
                                                                        name="your-email"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> Company/Organization </label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="company-organization"
                                                                >
                                                                    <input
                                                                        size={40}
                                                                        className="form-control"
                                                                        aria-invalid="false"
                                                                        defaultValue=""
                                                                        type="text"
                                                                        name="company-organization"
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <p>
                                                                <label> How can we help you?*</label>
                                                                <br />
                                                                <span
                                                                    className="wpcf7-form-control-wrap"
                                                                    data-name="your-message"
                                                                >
                                                                    <textarea
                                                                        cols={40}
                                                                        rows={10}
                                                                        className="form-control"
                                                                        aria-required="true"
                                                                        aria-invalid="false"
                                                                        name="your-message"
                                                                        defaultValue={""}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>
                                                                <input
                                                                    type="checkbox"
                                                                    className="mr-1"
                                                                    id="chkIagree"
                                                                />
                                                                I confirm that I have read and understood the Terms of
                                                                Service and Privacy Policy *
                                                            </label>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="nihaobtn mt-3 mb-4">
                                                                <Link
                                                                    to="#"
                                                                    id="SaveBTN"
                                                                    className="wow fadeInLeft"
                                                                    style={{
                                                                        visibility: "visible",
                                                                        animationName: "fadeInLeft"
                                                                    }}
                                                                >
                                                                    Submit
                                                                    <span>
                                                                        <img
                                                                            src="images/readmore.png"
                                                                            alt="arrow-right"
                                                                        />
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="wpcf7-response-output"
                                                            aria-hidden="true"
                                                        />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="back_top" onclick="topFunction()" id="myBtn">
                    <i className="fa fa-long-arrow-alt-right" />
                </div>
            </>


        </>

    )
}
export default About;
