// ScrollToTopButton.js
import React, { useState, useEffect } from 'react';


const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show or hide the button based on scroll position
        const handleScroll = () => {
            if (window.scrollY > 20) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

       
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        // Scroll to the top of the document
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <button
            className={`scrollToTopButton ${isVisible ? 'visible' : ''}`}
            onClick={scrollToTop}
            title="Go to top"
        >
            <i className="fa-solid fa-arrow-up"/>
        </button>
    );
};

export default ScrollToTopButton;
