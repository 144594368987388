import About from "./components/About";
import ContactUs from "./components/ContactUs";
import GetQuate from "./components/GetQuate";
import Home from "./components/Home";
import Kyc from "./components/Kyc";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AirfreightServices from "./components/Servicepages/AirfreightServices";
import CustomClearance from "./components/Servicepages/CustomClearance";
import GroundFreight from "./components/Servicepages/GroundFreight";
import ImportExport from "./components/Servicepages/ImportExport";
import SeafreightServices from "./components/Servicepages/SeaFreightServices";
import WareHouse from "./components/Servicepages/Warehousing";
import TearmAndConditions from "./components/TearmAndConditions";


const AppRoutes = [
    {
        index: true,
        path:'/Home',
        element: < Home />
    },
    {
        path: '/About',
        element: <About />
    },
    {
        path: '/Kyc',
        element: <Kyc />
    },
   
    {
        path: '/ContactUs',
        element: <ContactUs />
    },
    {
        path: '/airfreightservices',
        element: <AirfreightServices />
    },
    {
        path: '/seafreightservices',
        element: <SeafreightServices/>
    },
    {
        path: '/groundfreightservices',
        element: <GroundFreight />
    },
    {
        path: '/warehousingservices',
        element: <WareHouse />
    },
    {
        path: '/customclearanceservices',
        element: <CustomClearance />
    },
    {
        path: '/importexportservices',
        element: <ImportExport />
    },
    {
        path: '/PrivacyPolicy',
        element: <PrivacyPolicy />
    },
    {
        path: '/TermAndCondition',
        element: <TearmAndConditions />
    },
];

export default AppRoutes;
