import React, { useEffect, useRef, useState } from 'react';
import {Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';


function ContactUs() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    const inputrefs = useRef([]);
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
        PhoneNo: '',
        EmailAddress: '',
        Website: '',
        Subject: '',
        Message: '',
        IsTermsAndConditions: true
    });

    const [errors, setErrors] = useState({});
    const HandleChange = (prop, value) => {
        setFormData(formData => ({ ...formData, [prop]: value }))
    }
    const token = process.env.REACT_APP_SECRET_KEY
    const LoginToken = process.env.REACT_APP_LOGIN_KEY

    //token Data
    const TokenData = {
        AuthToken: token,
        LoginToken: LoginToken,
        UserCode:"Admin"
    }

    //const handleSubmit = () => {
    //    post(`${API_SERVER}api/Web/ContactUs`, { ...TokenData, ...formData }, (res) => {
    //        if (res && res.status) {
    //                if (res.status === "ERROR") {
    //                    toast.error(res.message)
    //            } else (res.status === "SUCCESS") {
    //                    toast.success(res.message)
    //                }
    //            }
    //        });
    //}

    const handleSubmit = async (event) => {
        event.preventDefault();
            const data = { ...formData, ...TokenData }
            await fetch(`api/Web/ContactUs`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
            }).then(async (response) => {
                const res = await response.json();
                if (res.status === 'SUCCESS') {
                    toast.success(res.message)
                } else if (res.status === 'ERROR') {
                    toast.error(res.message, {
                        position: 'top-center',
                    });
                }
                inputrefs.current[res.focus].focus()
            })
    }
    const SCROLL = document.getElementById('SCROLL');

    const ScrollFunction = async () => {
        // Scroll to the districtDataTable
        if (SCROLL) {
            SCROLL.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });

        }
    }
    useEffect(() => {
  
        document.title = 'Contact Us | Nihao Logistics'
        window.scrollTo(0, 0);
    }, [navigate])

    return (
        <>
            <Helmet>
              
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                <meta name="description" content="Contact us for all your import-export, delivery, transportation, and logistics needs. We provide reliable and efficient solutions to support your business operations." />
                <meta property="og:title" content="Contact Us | Nihao Logistics" />
                <meta property="og:description" content="Contact us for all your import-export, delivery, transportation, and logistics needs. We provide reliable and efficient solutions to support your business operations. " />
                <meta property="og:url" content="https://www.nihaologistics.com/contact-us" />
            </Helmet>
            <section className="home-section" id="SCROLL">
                <div className="container">
                    <div className="about-banner">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <div className="about-content">
                                    <div className="col-lg-10">
                                        <h1>
                                            Contact<span className="red"> Us</span>
                                        </h1>
                                        <p>Have a query? Please connect us</p>
                                        <p>We will be happy to assist you, please fill the form.</p>
                                        <div className="nihaobtn mt-3 mb-4">
                                            <Link
                                                to="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                id="SaveBTN"
                                                className="wow fadeInLeft"
                                                style={{
                                                    visibility: "visible",
                                                    animationName: "fadeInLeft"
                                                }}
                                            >
                                                Get a Free Quote
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="right-banner">
                                    <img src="images/contact-us.png" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clientele">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="heading mb-1">
                            <h2>Integrated logistics and supply chain solutions</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                             <OwlCarousel
                                className="owl-theme owl-crousal"
                                loop={true}
                                margin={20}
                                autoplay={true}
                                autoplayTimeout={3000}
                                nav={false}
                                dots={false}
                                center={true}
                                autoplaySpeed={2000}
                                responsiveClass={true}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 5
                                    }
                                }}
                            >
                                <div className="item">
                                    <img src="images/client-logo/air.jpg" />
                                    <h4>Air Freight</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/sea.jpg" />
                                    <h4>Sea Freight</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/trans.jpg" />
                                    <h4>Transportation</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/project.jpg" />
                                    <h4>Project Logistics &amp;</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/last.jpg" />
                                    <h4>Last Mile Delivery</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/custom.jpg" />
                                    <h4>Customs Brokerage</h4>
                                </div>
                                {/*<div class="item">
                  <img src="images/client-logo/cater.jpg">
                  <h4>Chartering Services</h4>
              </div>*/}
                                <div className="item">
                                    <img src="images/client-logo/whouse.jpg" />
                                    <h4>Warehousing &amp; Distribution</h4>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contact-page">
                <div className="container">
                    <div className="row contact-box">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-list">
                                <div className="contact-icon">
                                    <i className="fa fa-phone" aria-hidden="true" />
                                </div>
                                <div className="contact-header">
                                    <span> Any Questions? Call us</span>
                                    <a href="tel:+91-9716612304">+91-9716612304,</a>
                                    <a href="tel:+011-44723329"> 011-44723329</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-list">
                                <div className="contact-icon">
                                    <i className="fa fa-envelope" aria-hidden="true" />
                                </div>
                                <div className="contact-header">
                                    <span> Any Questions? Email us</span>
                                    <a href="mailto:sales@nihaologistics.com">
                                        sales@nihaologistics.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-list">
                                <div className="contact-icon">
                                    <i className="fa fa-address-card" aria-hidden="true" />
                                </div>
                                <div className="contact-header">
                                    <span>
                                        Plot In KH. No. 835 K-2 G/F, Mahipalpur Extn., Near Vasant Kunj
                                        Road MHP-6128, New Delhi-110037
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-7">
                            <div className="section-heading">
                                <span className="sub-title active-shape"> Need Any Help?</span>
                                <h3 className="title">Get in Touch With Us</h3>
                            </div>
                            <div className="animate-form d-flex align-items-center">
                                <form className="animate-form">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    ref={ref => (inputrefs.current['txtFirstName'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtLastName'].focus()
                                                        };
                                                    }}
                                                    value={formData.FirstName}
                                                    error={errors.FirstName}
                                                    onChange={(e) => HandleChange('FirstName', e.target.value)}
                                                    placeholder=""
                                                    
                                                />
                                                <label className="form-label">First Name <span style={{ color:"red" }}> *</span></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    ref={ref => (inputrefs.current['txtLastName'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtPhoneNo'].focus()
                                                        };
                                                    }}
                                                    value={formData.LastName}
                                                    error={errors.LastName}
                                                    onChange={(e) => HandleChange('LastName', e.target.value)}
                                                    placeholder=""
                                                />
                                                <label className="form-label">Last Name</label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    ref={ref => (inputrefs.current['txtPhoneNo'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtEmailAddress'].focus()
                                                        };
                                                    }}
                                                    maxLength={10 }
                                                    value={formData.PhoneNo}
                                                    error={errors.PhoneNo}
                                                    onChange={(e) => HandleChange('PhoneNo', e.target.value)}
                                                    placeholder="" />
                                                <label className="form-label">Phone <span style={{ color: "red" }}> *</span></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    value={formData.EmailAddress}
                                                    ref={ref => (inputrefs.current['txtEmailAddress'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtWebsite'].focus()
                                                        };
                                                    }}
                                                    error={errors.EmailAddress}
                                                    onChange={(e) => HandleChange('EmailAddress', e.target.value)}
                                                    placeholder=""
                                                />
                                                <label className="form-label"> Email Address <span style={{ color: "red" }}> *</span></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    ref={ref => (inputrefs.current['txtWebsite'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtSubject'].focus()
                                                        };
                                                    }}
                                                    value={formData.Website}
                                                    error={errors.Website}
                                                    onChange={(e) => HandleChange('Website', e.target.value)}
                                                    placeholder="" />
                                                <label className="form-label">Website <span style={{ color: "red" }}> *</span></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="form-div">
                                                <input type="text" className="form-input"
                                                    value={formData.Subject}
                                                    ref={ref => (inputrefs.current['txtSubject'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['txtMessage'].focus()
                                                        };
                                                    }}
                                                    error={errors.Subject} onChange={(e) => HandleChange('Subject', e.target.value)} placeholder="" />
                                                <label className="form-label">Subject <span style={{ color: "red" }}> *</span></label>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-div">
                                                <input
                                                   
                                                    type="text"
                                                    className="form-input textarea"
                                                    ref={ref => (inputrefs.current['txtMessage'] = ref)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            inputrefs.current['chkIsTermsAndConditions'].focus()
                                                        };
                                                    }}
                                                    placeholder=""
                                                    value={formData.Message}
                                                    error={errors.Message}
                                                    onChange={(e) => HandleChange('Message', e.target.value)}
                                                    style={{ minHeight: "99px" }}
                                                />
                                                <label className="form-label"> Message <span style={{ color: "red" }}> *</span></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group theme-button" >
                                        <label style={{ position: "relative", bottom: "40px" }} >
                                            <input type="checkbox" className="mr-1" 
                                                ref={ref => (inputrefs.current['chkIsTermsAndConditions'] = ref)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSubmit()
                                                    };
                                                }}
                                                checked={formData.IsTermsAndConditions}
                                                onChange={(e) => HandleChange('IsTermsAndConditions', e.target.checked)}
                                                id="chkIagree" />I
                                            confirm that I have read and understood the Terms of Service
                                            and Privacy Policy *
                                        </label>
                                    </div>
                                    {/*<div className="nihaobtn">*/}
                                    {/*    <button type="button" */}
                                           
                                    {/*        onClick={handleSubmit}*/}
                                    {/*        className="btn btn-rounded btn-primary">*/}
                                    {/*        <span className=" text-white me-2">*/}
                                    {/*            <i className="fa">&#xf0c7;</i>*/}

                                    {/*        </span>Save</button>*/}
                                        {/*<Link*/}
                                        {/*    to="#"*/}
                                        {/*    data-bs-toggle="modal"*/}
                                        {/*    data-bs-target="#exampleModal"*/}
                                        {/*    onclick*/}
                                        {/*    id="SaveBTN"*/}
                                        {/*    className="wow fadeInLeft"*/}
                                        {/*    style={{ visibility: "visible", animationName: "fadeInLeft" }}*/}
                                        {/*>*/}
                                        {/*    Get a Free Quote*/}
                                        {/*    <span>*/}
                                        {/*        <img src="images/readmore.png" alt="arrow-right" />*/}
                                        {/*    </span>*/}
                                        {/*</Link>*/}
                                 {/*   </div>*/}
                                     <div className="form-group">
                                        <div className="nihaobtn mt-3 mb-4" style={{ position: "relative", bottom: "40px" }}>
                                                            <a
                                                                onClick={handleSubmit}
                                                                    id="SaveBTN"
                                                                    className="wow fadeInLeft"
                                                                    style={{
                                                                        visibility: "visible",
                                                                        animationName: "fadeInLeft"
                                                                    }}
                                                                >
                                                                    Submit
                                                                    <span>
                                                                        <img
                                                                            src="images/readmore.png"
                                                                            alt="arrow-right"
                                                                        />
                                                                </span>
                                                            </a>
                                                            </div>
                                                        </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="google-map">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.992708237089!2d77.1272430761952!3d28.539938888278176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d9aac76dd0d%3A0x85c1acfbdaed62ab!2sNihao%20Logistics!5e0!3m2!1sen!2sin!4v1700716378681!5m2!1sen!2sin"
                                    width="100%"
                                    height="100%"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div
                className="modal fade modal-start-project"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="btnCloseModal"
                                className="btn-close close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="flex-row">
                                <div className="col modal-left-col modal-info">
                                    <div className="highlighted-serivces">
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/delivery.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Safe &amp; Secure Delivery</h5>
                                                <p>
                                                    A higher level of competence, using state-of-the-art
                                                    software and experienced professionals to improve overall
                                                    safety and security for parcel.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/save-money.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Cost Savings</h5>
                                                <p>
                                                    Effective instruments, which aim to control cost by
                                                    harnessing the power of operational optimization, offer a
                                                    more cost-effective, flexible and dynamic solution of
                                                    delivering on time than conventional measures.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/optimization.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Transparency</h5>
                                                <p>Service Price Calculater-What You See is What is Pay.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/deadline.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Assurance</h5>
                                                <p>
                                                    Continued Promise of Extended Warranty &amp; Part
                                                    Availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col modal-right-col modal-form">
                                    <div className="content-inner">
                                        <div className="form-headingtext">
                                            <span className="title-label">Connect with us</span>
                                            <h2>
                                                Get <span>No-Cost Quote</span> and Expert Consultation
                                            </h2>
                                        </div>
                                        <div className="contact-form project-form">
                                            <div
                                                className="wpcf7 js"
                                                id="wpcf7-f36355-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                    <ul />
                                                </div>
                                                <form
                                                    action="#"
                                                    method="post"
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                >
                                                    <div className="form-group">
                                                        <p>
                                                            <label>Name*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Email*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Company/Organization </label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="company-organization"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="company-organization"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> How can we help you?*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    name="your-message"
                                                                    defaultValue={""}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                id="chkIagree"
                                                            />
                                                            I confirm that I have read and understood the Terms of
                                                            Service and Privacy Policy *
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <Link
                                                                to="#"
                                                                id="SaveBTN"
                                                                className="wow fadeInLeft"
                                                                style={{
                                                                    visibility: "visible",
                                                                    animationName: "fadeInLeft"
                                                                }}
                                                            >
                                                                Submit
                                                                <span>
                                                                    <img
                                                                        src="images/readmore.png"
                                                                        alt="arrow-right"
                                                                    />
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </>

    )
}
export default ContactUs