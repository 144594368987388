import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
function SeafreightServices() {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Safe & Secure Sea Freight Services | Worldwide Shipping Network'
    }, [navigate])
    return (
        <>
            <Helmet>
                <title>Safe & Secure Sea Freight Services | Worldwide Shipping Network</title>
                <meta name="google-site-verification" content="3a0ckmb0SaE0ACU7AelqWcb0Bpf46SoSioBa6rFgd2o" />

                <meta name="description" content="With our comprehensive sea freight services, you can enjoy flexible shipping options and expert logistics support. We handle your cargo with care and efficiency." />
                <meta property="og:title" content="Safe & Secure Sea Freight Services | Worldwide Shipping Network" />
                <meta property="og:description" content="With our comprehensive sea freight services, you can enjoy flexible shipping options and expert logistics support. We handle your cargo with care and efficiency." />
                <meta property="og:url" content="https://www.nihaologistics.com/sea-freight-services" />
                <link rel="canonical" href="https://www.nihaologistics.com/sea-freight-services" />

            </Helmet>
            <section className="home-section">
                <div className="container">
                    <div className="about-banner">
                        <div className="row align-items-center">
                            <div className="col-lg-7 col-md-10 mx-auto">
                                <div className="about-content">
                                    <div className="col-lg-10">
                                        <h1>
                                            Sea Cargo<span className="red"> Services</span>
                                        </h1>
                                        <p>
                                            Sea freight, also known as ocean freight, is a method of
                                            shipping goods and cargo by ocean-going vessels. It is the
                                            most economical mode of transportation for large quantities of
                                            heavy and bulky goods, such as raw materials, machinery, and
                                            manufactured goods.
                                        </p>
                                        <div className="nihaobtn mt-3 mb-4">
                                            <a
                                                href="#"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                id="SaveBTN"
                                                className="wow fadeInLeft"
                                                style={{
                                                    visibility: "visible",
                                                    animationName: "fadeInLeft"
                                                }}
                                            >
                                                Get a Free Quote
                                                <span>
                                                    <img src="images/readmore.png" alt="arrow-right" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="right-banner">
                                    <img
                                        src="images/sea-freight.png"
                                        className="img-fluid"
                                        alt="sea cargo services"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="clientele">
                <div className="container">
                    <div className="col-lg-12">
                        <div className="heading mb-1">
                            <h2>Integrated logistics and supply chain solutions</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel
                                className="owl-theme owl-crousal"
                                loop={true}
                                margin={20}
                                autoplay={true}
                                autoplayTimeout={3000}
                                nav={false}
                                dots={false}
                                center={true}
                                autoplaySpeed={2000}
                                responsiveClass={true}
                                responsive={{
                                    0: {
                                        items: 1
                                    },
                                    600: {
                                        items: 3
                                    },
                                    1000: {
                                        items: 5
                                    }
                                }}
                            >
                                <div className="item">
                                    <img src="images/client-logo/air.jpg" alt="air freight" />
                                    <h4>Air Freight</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/sea.jpg" alt="sea freight" />
                                    <h4>Sea Freight</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/trans.jpg" alt="transportation" />
                                    <h4>Transportation</h4>
                                </div>
                                <div className="item">
                                    <img
                                        src="images/client-logo/project.jpg"
                                        alt="project logistics"
                                    />
                                    <h4>Project Logistics &amp;</h4>
                                </div>
                                <div className="item">
                                    <img src="images/client-logo/last.jpg" alt="last mile delivery" />
                                    <h4>Last Mile Delivery</h4>
                                </div>
                                <div className="item">
                                    <img
                                        src="images/client-logo/custom.jpg"
                                        alt="customs brokerage"
                                    />
                                    <h4>Customs Brokerage</h4>
                                </div>
                                {/*<div class="item">
                  <img src="images/client-logo/cater.jpg">
                  <h4>Chartering Services</h4>
              </div>*/}
                                <div className="item">
                                    <img
                                        src="images/client-logo/whouse.jpg"
                                        alt="warehousing & distribution"
                                    />
                                    <h4>Warehousing &amp; Distribution</h4>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-details">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="services-details-content">
                                <h3 className="mt-4">Sea Cargo Services</h3>
                                <p>
                                    Sea freight, also known as ocean freight, is a method of shipping
                                    goods and cargo by ocean-going vessels. It is the most economical
                                    mode of transportation for large quantities of heavy and bulky
                                    goods, such as raw materials, machinery, and manufactured goods.
                                    Sea freight allows for the shipment of goods to almost any
                                    destination worldwide and is a vital component of global trade.
                                    Due to its cost-effectiveness and speed, sea freight is often
                                    preferred over air freight for international shipping.
                                    Additionally, it also has capabilities to carry various types of
                                    cargo including containerized, break bulk, and roll-on roll-off
                                    cargo.
                                </p>
                                <p>
                                    Move your cargo across the globe with ease and confidence. Nihao
                                    Logistics is India's premier sea freight forwarding company,
                                    providing efficient, reliable and cost-effective solutions to
                                    customers worldwide. With years of experience and a commitment to
                                    excellence has been recognized by our clients who have relied on
                                    us for several years.We are proud to have earned the trust of our
                                    customers, which has made us one of the most trusted names in sea
                                    freight services.
                                </p>
                                {/*<div class="content mt-40">
                  <div class="feature-list mt-4">
                      <div class="row clearfix">
                          <div class="col-lg-6 col-md-6 col-sm-12 column">
                              <img class="mb-3 img-fluid" src="images/sea1.jpg" alt="images">
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 column">
                              <img class="mb-3 img-fluid" src="images/sea2.jpg" alt="images">
                          </div>
                      </div>
                  </div>
              </div>*/}
                                {/*Start image Section */}
                                <div className="image-section">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 wow fadeInUp">
                                            <div className="inner-box">
                                                <div className="image-box">
                                                    <div className="image">
                                                        <a href="#">
                                                            <img src="images/sea1.jpg" alt="sea cargo services" />
                                                            <img src="images/sea1.jpg" alt="sea cargo services" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-4 col-md-6 wow fadeInUp"
                                            data-wow-delay="300ms"
                                        >
                                            <div className="inner-box">
                                                <div className="image-box">
                                                    <div className="image">
                                                        <a href="#">
                                                            <img src="images/sea2.jpg" alt="sea cargo services" />
                                                            <img src="images/sea2.jpg" alt="sea cargo services" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-lg-4 col-md-6 wow fadeInUp"
                                            data-wow-delay="600ms"
                                        >
                                            <div className="inner-box">
                                                <div className="image-box">
                                                    <div className="image">
                                                        <a href="#">
                                                            <img src="images/sea3.jpg" alt="sea cargo services" />
                                                            <img src="images/sea3.jpg" alt="sea cargo services" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*End image Section */}
                                <section className="faq mt-3">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="heading">
                                                <h3>Frequently Asked Questions (FAQ)</h3>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseOne"
                                                            aria-expanded="true"
                                                            aria-controls="collapseOne"
                                                        >
                                                            1. What value-added services does Nihao Logistics
                                                            offer?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseOne"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <p>
                                                                We offer a range of value-added services, including
                                                                custom clearance and forwarding, full-container load
                                                                (FLC) consolidation, less-container load (LCL)
                                                                consolidation, on-the-spot/factory stuffing of
                                                                containers, multi-model transport operators,
                                                                infrastructure and service facilities at all sea
                                                                ports and dry ports, dry or liquid bulk shipments,
                                                                insurance and packing options, customs warehousing,
                                                                automated documentation, cargo tracking system, and
                                                                communication facilities.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseTwo"
                                                            aria-expanded="false"
                                                            aria-controls="collapseTwo"
                                                        >
                                                            2. What kind of air freight services do you provide?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseTwo"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingTwo"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <p>
                                                                We provide expedited air freight and international
                                                                air freight services to destinations anywhere in the
                                                                world. Our services are designed to be safe, timely,
                                                                seamless, and cost-effective, and we work closely
                                                                with our clients to select the most appropriate
                                                                carrier for their shipments.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#collapseThree"
                                                            aria-expanded="false"
                                                            aria-controls="collapseThree"
                                                        >
                                                            3. How do I track the status of my air freight
                                                            shipment?
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseThree"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingThree"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <p>
                                                                You can track the status of your shipment through
                                                                this link https://www.nihaologistics.com/tracking.
                                                                If you have any questions or concerns about the
                                                                status of your shipment, our team is always
                                                                available to provide assistance and support.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        {/*End Services Details Content*/}
                    </div>
                </div>
            </section>
            <div
                className="modal fade modal-start-project"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                id="btnCloseModal"
                                className="btn-close close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body">
                            <div className="flex-row">
                                <div className="col modal-left-col modal-info">
                                    <div className="highlighted-serivces">
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/delivery.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Safe &amp; Secure Delivery</h5>
                                                <p>
                                                    A higher level of competence, using state-of-the-art
                                                    software and experienced professionals to improve overall
                                                    safety and security for parcel.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/save-money.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Cost Savings</h5>
                                                <p>
                                                    Effective instruments, which aim to control cost by
                                                    harnessing the power of operational optimization, offer a
                                                    more cost-effective, flexible and dynamic solution of
                                                    delivering on time than conventional measures.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/optimization.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Transparency</h5>
                                                <p>Service Price Calculater-What You See is What is Pay.</p>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="icon">
                                                <img src="images/icons/deadline.png" />
                                            </div>
                                            <div className="content">
                                                <h5>Assurance</h5>
                                                <p>
                                                    Continued Promise of Extended Warranty &amp; Part
                                                    Availability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col modal-right-col modal-form">
                                    <div className="content-inner">
                                        <div className="form-headingtext">
                                            <span className="title-label">Connect with us</span>
                                            <h2>
                                                Get <span>No-Cost Quote</span> and Expert Consultation
                                            </h2>
                                        </div>
                                        <div className="contact-form project-form">
                                            <div
                                                className="wpcf7 js"
                                                id="wpcf7-f36355-o1"
                                                lang="en-US"
                                                dir="ltr"
                                            >
                                                <div className="screen-reader-response">
                                                    <p role="status" aria-live="polite" aria-atomic="true" />{" "}
                                                    <ul />
                                                </div>
                                                <form
                                                    action="#"
                                                    method="post"
                                                    className="wpcf7-form init"
                                                    aria-label="Contact form"
                                                >
                                                    <div className="form-group">
                                                        <p>
                                                            <label>Name*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-name"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="name"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="your-name"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Email*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-email"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    autoComplete="email"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="email"
                                                                    name="your-email"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> Company/Organization </label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="company-organization"
                                                            >
                                                                <input
                                                                    size={40}
                                                                    className="form-control"
                                                                    aria-invalid="false"
                                                                    defaultValue=""
                                                                    type="text"
                                                                    name="company-organization"
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <p>
                                                            <label> How can we help you?*</label>
                                                            <br />
                                                            <span
                                                                className="wpcf7-form-control-wrap"
                                                                data-name="your-message"
                                                            >
                                                                <textarea
                                                                    cols={40}
                                                                    rows={10}
                                                                    className="form-control"
                                                                    aria-required="true"
                                                                    aria-invalid="false"
                                                                    name="your-message"
                                                                    defaultValue={""}
                                                                />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                className="mr-1"
                                                                id="chkIagree"
                                                            />
                                                            I confirm that I have read and understood the Terms of
                                                            Service and Privacy Policy *
                                                        </label>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="nihaobtn mt-3 mb-4">
                                                            <a
                                                                href="#"
                                                                id="SaveBTN"
                                                                className="wow fadeInLeft"
                                                                style={{
                                                                    visibility: "visible",
                                                                    animationName: "fadeInLeft"
                                                                }}
                                                            >
                                                                Submit
                                                                <span>
                                                                    <img
                                                                        src="images/readmore.png"
                                                                        alt="arrow-right"
                                                                    />
                                                                </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="wpcf7-response-output"
                                                        aria-hidden="true"
                                                    />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="back_top" onclick="topFunction()" id="myBtn">
                <i className="fa fa-long-arrow-alt-right" />
            </div>
        </>

    )
}
export default SeafreightServices