import { useEffect } from "react"


const PDFView = () => {
    useEffect(() => {
        document.title = 'PDF Viewer'
    }, [])

    let url = window.location.href
    let pdf = url.split('#')[1]
    let title = url.split('#')[0]

    return (
        <div className='p-0 m-0 container-fluid' style={{ height: '100vh', width: '100%' }}>
            <div className='row' style={{ height: "100vh", width: "100%" }}>
                <iframe className='p-0 m-0' title={title} src={pdf} />
            </div>
        </div>
    )
}
export default PDFView
