import React, { useEffect } from 'react';
import {  Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

const Footer = () => {
    function scrollToTop() {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    useEffect(() => {

        window.scrollTo(0, 0);
    }, [navigate]);
    return (
        <section className="footer-section" id="footer_section">
            <div className="footer-warea">
                <div className="container">
                    <div className="middle-portion">
                        <div className="row">
                            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-5">
                                <div className="white-logo">
                                    <Link to="index.html">
                                        <img
                                            src="images/footer-logo.png"
                                            alt="Nihao Logo"
                                            className="ftr-logo"
                                        />
                                    </Link>
                                </div>
                                <div className="list-unstyled mt-3 text-left">
                                    <p></p>
                                    <p>
                                        Plot In KH. No. 835 K-2 G/F, Mahipalpur Extn., Near Vasant
                                        Kunj Road MHP-6128, New Delhi-110037
                                    </p>
                                    <p />
                                    <p></p>
                                    <p>
                                        Mail:{" "}
                                        <a href="mailto:sales@nihaologistics.com">
                                            sales@nihaologistics.com
                                        </a>{" "}
                                    </p>
                                    <p />
                                    <p></p>
                                    <p>
                                        Call:{" "}
                                        <a href="tel:+91-9716612304"> +91-9716612304 ,</a>{" "}
                                        <a href="tel:011-44723329">011-44723329 </a>{" "}
                                    </p>
                                    <p />
                                    <p></p>
                                    <p>
                                        GST No. <>07AAHCN9942J1ZW</>
                                    </p>
                                    <p />
                                    <div className="social-icons">
                                        <p> Follow Us:</p>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/nihaologistics"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.instagram.com/nihaologistics_official/"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.linkedin.com/company/nihaologistics"
                                                    target="_blank"
                                                >
                                                    <i className="fab fa-brands fa-linkedin-in" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://twitter.com/nihao_logistics"
                                                    target="_blank"
                                                >
                                                    <i className="fa-brands fa-x-twitter" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="useful_links">
                                    <h5 className="text-white">Important Links</h5>
                                    <ul className="list-unstyled mb-3 useful-lnk">
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/">Home</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/About">About Us</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/Kyc">KYC</Link>{" "}
                                        </li>
                                        {/*<li className="arrow">*/}
                                        {/*    {" "}*/}
                                        {/*    <i className="fa-solid fa-arrow-right-long" />{" "}*/}
                                        {/*    <Link to="/GetQuate">Get Quote</Link>{" "}*/}
                                        {/*</li>*/}
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/ContactUs">Contact Us</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/">Track Shipment</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link onClick={scrollToTop}>Terms and Conditions</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/PrivacyPolicy">Privacy &amp; Policy</Link>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                                <div className="useful_links">
                                    <h5 className="text-white">Services</h5>
                                    <ul className="list-unstyled mb-3 useful-lnk">
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/airfreightservices">Air Freight</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/seafreightservices">Sea Freight</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/groundfreightservices">Ground Freight</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/warehousingservices">Warehousing</Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/customclearanceservices">
                                                Custom-Clearance
                                            </Link>{" "}
                                        </li>
                                        <li className="arrow">
                                            {" "}
                                            <i className="fa-solid fa-arrow-right-long" />{" "}
                                            <Link to="/importexportservices">Import Export</Link>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-logo">
                                <ul>
                                    <li>
                                        <img
                                            src="images/msme-logo.png"
                                            className="image1 img-fluid"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src="images/int-trade-council.png"
                                            className="image1  img-fluid"
                                        />
                                    </li>
                                    <li>
                                        <img src="images/GLA.png" className="image3 img-fluid" />
                                    </li>
                                    <li>
                                        <img src="images/iso-logo.png" className="image2 img-fluid" />
                                    </li>
                                </ul>
                            </div>
                            <div className="bottom-portion col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                                        <div className="copyroght">
                                            <p>
                                                © 2023 Nihao Logistics &amp; Marketing all rights
                                                reserved.
                                            </p>
                                        </div>
                                    </div>
                                    {/*<div class="col-lg-6 col-md-12 col-sm-12 col-12 text-center text-md-right">
                              <div class="designed_by">
                                  <p>Powered by: <a href="https://www.sagarinfotech.com/" target="_blank" rel="nofollow">Sagar informatics Pvt. Ltd.</a></p>
                              </div>
                          </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Footer;

